/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CollectionPersonModel } from './collectionPersonModel';
import { CollectionSegmentDto } from './collectionSegmentDto';
import { ProductType } from './productType';
import { DateOnly } from '@lib-utils';


/**
 * Кредитный договор
 */
export interface CollectionContractDto { 
    id?: number;
    /**
     * Дата закрытия договора
     */
    contractCloseDate?: DateOnly | null;
    productType?: ProductType;
    /**
     * Признак наличия залога
     */
    propertyFlag?: string | null;
    /**
     * Признак наличия поручителя
     */
    guarantFlag?: string | null;
    /**
     * Признак наличия прочего обеспечения (кроме поручителя и залога)
     */
    guarantFlagOther?: string | null;
    /**
     * Признак цессии
     */
    cessionFlag?: string | null;
    /**
     * Тип цессии
     */
    cessionType?: string | null;
    /**
     * Дата цессии
     */
    cessionDate?: DateOnly | null;
    /**
     * Дата первичной продажи из банка
     */
    firstSaleDate?: DateOnly | null;
    /**
     * Признак списанного
     */
    writeOff?: string | null;
    /**
     * Дата списания
     */
    writeOffDate?: DateOnly | null;
    /**
     * Номер кредитного договора
     */
    creditContractNumber?: string | null;
    /**
     * Дата выдачи кредита
     */
    creditDate?: DateOnly | null;
    /**
     * Дата начала кредита
     */
    creditStartDate?: DateOnly | null;
    /**
     * Дата окончания кредита
     */
    creditEndDate?: DateOnly | null;
    /**
     * Размер кредита
     */
    creditTotalSum?: number | null;
    /**
     * Валюта кредита
     */
    currency?: string | null;
    /**
     * Процентная ставка по кредиту (первоначальная)
     */
    initialInterestRate?: number | null;
    /**
     * Тип продукта
     */
    projectType?: string | null;
    /**
     * Вид кредита
     */
    creditType?: string | null;
    /**
     * Состояние кредита на момент выгрузки
     */
    statusName?: string | null;
    /**
     * Процентная ставка по кредиту текущая
     */
    interestRateCurrent?: number | null;
    /**
     * Сегмент
     */
    segmentId?: number | null;
    segment?: CollectionSegmentDto;
    person?: CollectionPersonModel;
    /**
     * Этап collection
     */
    collectionStage?: string | null;
    /**
     * Дней в просрочке
     */
    daysDelay?: number | null;
    /**
     * Признак реструктуризации
     */
    restructuringSign?: string | null;
    /**
     * Общая задолженность, руб.
     */
    debtSumRub?: number | null;
    /**
     * Сумма аннуитета
     */
    sumAnnuity?: number | null;
    /**
     * День аннуитета
     */
    dayAnnuity?: number | null;
    /**
     * Просроченная задолженность, руб.
     */
    overdueDebt?: number | null;
    /**
     * Дата образования задолженности по основному долгу
     */
    overdueDate?: DateOnly | null;
    /**
     * Id заявки из конвейера
     */
    applicationId?: number | null;
    /**
     * Срок просрочки по FIFO
     */
    daysDelayFifo?: number | null;
    /**
     * Срок просрочки по LIFO
     */
    daysDelayLifo?: number | null;
    /**
     * Программа кредитования
     */
    creditProgram?: string | null;
    /**
     * Дата начисления пеней (кредит)
     */
    peneCreditDate?: DateOnly | null;
    /**
     * Дата начисления пеней (проценты)
     */
    penePercentDate?: DateOnly | null;
    /**
     * Дата выноса процентов
     */
    percentDate?: DateOnly | null;
}


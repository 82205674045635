/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * звонки и комментарии по взаимодействию с должником по телефону
 */
export interface OutsourcingCallModel { 
    /**
     * Идентификатор
     */
    id?: number;
    /**
     * Номер контракта
     */
    creditContractNumber?: string | null;
    /**
     * Идентификатор банка
     */
    bankId?: number;
    /**
     * Номер телефона клиента
     */
    phone?: string | null;
    /**
     * Тип телефона
     */
    phoneType?: string | null;
    /**
     * Результат звонка
     */
    resultId?: number;
    /**
     * Комментарий
     */
    comment?: string | null;
    /**
     * Дата звонка
     */
    callDate?: string | null;
}


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>Fnip.Collection.Utils.Enums.ProductType</b></p><p>Members:</p> <ul> <p><li><i>Auto</i> - Авто ФЛ</li></p> <p><li><i>AutoPpt</i> - ППТ Авто</li></p> </ul> 
 */
export type ProductType = 'Auto' | 'AutoPpt';

export const ProductType = {
    Auto: 'Auto' as ProductType,
    AutoPpt: 'AutoPpt' as ProductType
};


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>Fnip.Collection.Utils.Enums.ToDelete.OldActivityType</b></p><p>Members:</p> <ul> <p><li><i>OutgoingCall</i> - Исходящий звонок</li></p> <p><li><i>IncomingCall</i> - Входящий звонок</li></p> <p><li><i>NoAnswer</i> - СМС</li></p> <p><li><i>Email</i> - Email</li></p> <p><li><i>Departure</i> - Выезд</li></p> </ul> 
 */
export type OldActivityType = 'OutgoingCall' | 'IncomingCall' | 'NoAnswer' | 'Email' | 'Departure';

export const OldActivityType = {
    OutgoingCall: 'OutgoingCall' as OldActivityType,
    IncomingCall: 'IncomingCall' as OldActivityType,
    NoAnswer: 'NoAnswer' as OldActivityType,
    Email: 'Email' as OldActivityType,
    Departure: 'Departure' as OldActivityType
};


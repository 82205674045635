/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Получение контракта в работу
 */
export interface OutsourcingStartModel { 
    /**
     * Номер договора
     */
    creditContractNumber?: string | null;
    /**
     * Вид кредита
     */
    creditType?: string | null;
    /**
     * Тип проекта
     */
    projectType?: string | null;
    /**
     * Банк
     */
    bank?: string | null;
    /**
     * Фамилия
     */
    lastName?: string | null;
    /**
     * Имя
     */
    firstName?: string | null;
    /**
     * Отчество
     */
    middleName?: string | null;
    /**
     * Дата рождения заемшика
     */
    birthDate?: string | null;
    /**
     * Место рождения заемщика
     */
    birthPlace?: string | null;
    /**
     * Серия паспорта заемщика
     */
    passportSeries?: string | null;
    /**
     * Номер паспорта заемщика
     */
    passportNumber?: string | null;
    /**
     * Дата выдачи паспорта заемщика
     */
    passportDate?: string | null;
    /**
     * Кем выдан паспорт заемщику
     */
    passportIssuer?: string | null;
    /**
     * Код подразделения, выдавшего паспорт
     */
    passportIssuerCode?: string | null;
    /**
     * Адрес регистрации заемщика
     */
    registrationAddress?: string | null;
    /**
     * Место жительства заемщика
     */
    residenceAddress?: string | null;
    /**
     * Мобильный телефон
     */
    mobilePhone?: string | null;
    /**
     * Остальные телефоны
     */
    otherPhones?: string | null;
    /**
     * Дата закрытия кредита
     */
    creditCloseDate?: string | null;
    /**
     * Дата выдачи кредита
     */
    creditOpenDate?: string | null;
    /**
     * Сумма выдачи
     */
    creditAmount?: string | null;
    /**
     * Валюта кредита
     */
    creditCurrency?: string | null;
    /**
     * Сумма аннуитета
     */
    annuityAmount?: string | null;
    /**
     * Дата аннуитета
     */
    annuityDate?: string | null;
    /**
     * Номер счета для списания
     */
    accountNumber?: string | null;
    /**
     * Залог авто: модель и марка автомобиля
     */
    carModel?: string | null;
    /**
     * Залог авто: VIN
     */
    carVin?: string | null;
    /**
     * Залог авто: год производства автомобиля
     */
    carYear?: string | null;
    /**
     * Дата образования задолженности по основному долгу
     */
    debtDate?: string | null;
    /**
     * Ссудная задолженность, руб.
     */
    debtAmount?: string | null;
    /**
     * Процентная задолженность, руб.
     */
    debtOverdueAmount?: string | null;
    /**
     * Просроченная задолженность по процентам, руб.
     */
    interestOverdueAmount?: string | null;
    /**
     * Задолженность по пене за кредит, руб.
     */
    creditPenaltyAmount?: string | null;
    /**
     * Задолженность по пене за проценты, руб.
     */
    interestPenaltyAmount?: string | null;
    /**
     * Процентная задолженность, руб.
     */
    overdueAmount?: string | null;
    /**
     * Общая задолженность, руб.
     */
    totalDebtAmount?: string | null;
    /**
     * Срок непрерывной просрочки в днях
     */
    overdueDays?: string | null;
    /**
     * Выставлено требование на досрочный возврат
     */
    isDemand?: string | null;
    /**
     * Место работы
     */
    workPlace?: string | null;
    /**
     * Адрес работы
     */
    workAddress?: string | null;
    /**
     * ГРЗ (РСА)
     */
    carNumber?: string | null;
    /**
     * Страхователь ТС (РСА)
     */
    insuranceCompany?: string | null;
    /**
     * Собственник ТС (РСА)
     */
    carOwner?: string | null;
    /**
     * ТС используется в регионе (РСА)
     */
    carRegion?: string | null;
    /**
     * Отказ от взаимодействия
     */
    isRefuse?: string | null;
    /**
     * ФИО представителя
     */
    representativeName?: string | null;
    /**
     * Телефон представителя
     */
    representativePhone?: string | null;
    /**
     * Дата выгрузки
     */
    uploadDate?: string | null;
    /**
     * Документы
     */
    documents?: string | null;
}


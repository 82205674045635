import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { Nullable } from '@lib-utils';
import { CollectionCourtDealDto, CollectionCourtDealRequest } from '@lib-collection/api';
import { ProceedingService } from './abstract';

@Injectable({ providedIn: 'root' })
export class JudicialProceedingService extends ProceedingService {
  getProceedings(personId: Nullable<number>, page = 0, perPage = 10) {
    return this.crmCollectionApiService
      .apiCrmCollectionCourtDealsGet(personId ?? undefined, page, perPage)
      .pipe(map((response) => response.data!));
  }

  addProceeding(dto: CollectionCourtDealRequest) {
    return this.crmCollectionApiService.apiCrmCollectionCourtDealsPost(dto);
  }

  getProceeding(id: number) {
    return this.crmCollectionApiService
      .apiCrmCollectionCourtDealsIdGet(id)
      .pipe(switchMap((result) => (result.data ? of(result.data) : EMPTY)));
  }

  updateProceeding(id: number, item: CollectionCourtDealDto) {
    return this.crmCollectionApiService.apiCrmCollectionCourtDealsIdPut(id, item);
  }

  removeProceeding(id: number): Observable<number> {
    return this.crmCollectionApiService.apiCrmCollectionCourtDealsIdDelete(id).pipe(map(() => id));
  }
}

/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { CollectionResultActivityModel } from './collectionResultActivityModel';
import { OldActivityType } from './oldActivityType';
import { DateTime } from '@lib-utils';


/**
 * Данные клиента
 */
export interface CollectionActivityModel { 
    /**
     * Идентификатор
     */
    id?: number;
    /**
     * Идентификатор пользователя, создавшего сущность
     */
    creatorId?: number;
    creator?: UserModel;
    /**
     * Дата создания сущности
     */
    created?: DateTime;
    /**
     * Идентификатор пользователя, обновившего сущность
     */
    updatorId?: number;
    updator?: UserModel;
    /**
     * Дата обновления сущность
     */
    updated?: DateTime;
    /**
     * Идентификатор данных клиента
     */
    personId?: number;
    /**
     * Кредитный договор
     */
    contractId?: number;
    /**
     * Время и дата обращения
     */
    activityDate?: DateTime;
    /**
     * Результат активности
     */
    resultActivityId?: number | null;
    resultActivity?: CollectionResultActivityModel;
    /**
     * Контактная информация
     */
    contactInfo?: string | null;
    /**
     * Контактное лицо
     */
    contactUser?: string | null;
    activityType?: OldActivityType;
    /**
     * Комментарий
     */
    comment?: string | null;
}


export * from './bankDataImporter.service';
import { BankDataImporterApiService } from './bankDataImporter.service';
export * from './client.service';
import { ClientApiService } from './client.service';
export * from './contact.service';
import { ContactApiService } from './contact.service';
export * from './contract.service';
import { ContractApiService } from './contract.service';
export * from './crmCollection.service';
import { CrmCollectionApiService } from './crmCollection.service';
export * from './outsourcing.service';
import { OutsourcingApiService } from './outsourcing.service';
export * from './report.service';
import { ReportApiService } from './report.service';
export const APIS = [BankDataImporterApiService, ClientApiService, ContactApiService, ContractApiService, CrmCollectionApiService, OutsourcingApiService, ReportApiService];

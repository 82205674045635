/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Sex } from './sex';
import { DateOnly } from '@lib-utils';
import { DateTime } from '@lib-utils';
import { UserEntity } from './userEntity';


/**
 * Модель информации о физическом лице
 */
export interface PhysicalPersonEntity { 
    /**
     * Новая (несохранённая сущность)
     */
    readonly isNew?: boolean;
    /**
     * Идентификатор пользователя, создавшего сущность
     */
    creatorId?: number;
    creator?: UserEntity;
    /**
     * Дата создания сущности
     */
    created?: DateTime;
    /**
     * Идентификатор пользователя, обновившего сущность
     */
    updatorId?: number;
    updator?: UserEntity;
    /**
     * Дата обновления сущность
     */
    updated?: DateTime;
    /**
     * Идентификатор информации о физическом лице
     */
    id?: number;
    /**
     * Имя
     */
    firstName?: string | null;
    /**
     * Отчество
     */
    middleName?: string | null;
    /**
     * Фамилия
     */
    lastName?: string | null;
    /**
     * ФИО
     */
    readonly fullName?: string | null;
    sex?: Sex;
    /**
     * Дата рождения
     */
    birthday?: DateOnly | null;
    /**
     * СНИЛС
     */
    snils?: string | null;
    /**
     * ИНН
     */
    taxNumber?: string | null;
}


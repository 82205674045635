/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CollectionSegmentModel } from './collectionSegmentModel';


/**
 * Реакции
 */
export interface CollectionResultActivityModel { 
    /**
     * Идентификатор
     */
    id?: number;
    /**
     * Наименование
     */
    name?: string | null;
    /**
     * Приоритет
     */
    code?: string | null;
    /**
     * Флаг активности
     */
    isActivated?: boolean;
    /**
     * Группа доступа
     */
    accessGroup?: string | null;
    /**
     * Холд дней
     */
    holdDays?: number | null;
    /**
     * Маршрутизирующая
     */
    isRoutable?: boolean;
    /**
     * Идентификатор сегмента (для флага \"Маршрутизирующая\")
     */
    routableSegmentId?: number | null;
    routableSegment?: CollectionSegmentModel;
    /**
     * Сегменты
     */
    segmentIds?: Array<number> | null;
    /**
     * Сегменты
     */
    segments?: Array<CollectionSegmentModel> | null;
}


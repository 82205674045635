/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>Fnip.Collection.Utils.Enums.TotalResultType</b></p><p>Members:</p> <ul> <p><li><i>ContactWithDebtor</i> - Контакт с должником</li></p> <p><li><i>ContactWithOther</i> - Контакт с третьим лицом</li></p> <p><li><i>ContactFailed</i> - Контакт не состоялся</li></p> </ul> 
 */
export type TotalResultType = 'ContactWithDebtor' | 'ContactWithOther' | 'ContactFailed';

export const TotalResultType = {
    ContactWithDebtor: 'ContactWithDebtor' as TotalResultType,
    ContactWithOther: 'ContactWithOther' as TotalResultType,
    ContactFailed: 'ContactFailed' as TotalResultType
};


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CourtStatusType } from './courtStatusType';
import { CollectionContactDto } from './collectionContactDto';
import { DateTime } from '@lib-utils';


/**
 * Судебное производство
 */
export interface CollectionCourtDealDto { 
    id?: number;
    status?: CourtStatusType;
    /**
     * ID кредитного договора
     */
    contractId?: number | null;
    /**
     * ID клиента
     */
    clientId?: number | null;
    /**
     * ID долга
     */
    debtId?: number | null;
    /**
     * Дата возбуждения дела
     */
    startDate?: DateTime | null;
    /**
     * Дата приостановления
     */
    endDate?: DateTime | null;
    /**
     * Комментарий
     */
    comment?: string | null;
    /**
     * Банк
     */
    bank?: string | null;
    /**
     * Суд
     */
    court?: string | null;
    /**
     * Способ подачи
     */
    feedMethod?: string | null;
    /**
     * Дата подачи в суд
     */
    courtSubmitDate?: DateTime | null;
    /**
     * Номер дела в суде
     */
    number?: string | null;
    /**
     * Дата расторжения договора
     */
    contractTerminationDate?: DateTime | null;
    /**
     * Дата вынесения решения
     */
    decisionDate?: DateTime | null;
    /**
     * Дата вступления в силу
     */
    effectiveDate?: DateTime | null;
    /**
     * Сумма по решению суда
     */
    sum?: number | null;
    /**
     * Утвержденная пошлина
     */
    approvedDuty?: number | null;
    /**
     * Дата оплаты пошлины
     */
    paymentDutyDate?: DateTime | null;
    /**
     * Дата направления требования
     */
    requestDate?: DateTime | null;
    /**
     * Дата вынесения всей суммы на просрочку
     */
    overdueDate?: DateTime | null;
    /**
     * Результат по делу
     */
    result?: string | null;
    /**
     * Входящий номер
     */
    incomingNumber?: string | null;
    /**
     * Дата получения исполнительного листа
     */
    enforcementDate?: DateTime | null;
    /**
     * Дата мирового соглашения
     */
    settlementDate?: DateTime | null;
    /**
     * Дата мирового соглашения
     */
    sumSettlement?: number | null;
    /**
     * Дата решения по иску
     */
    decisionClaimDate?: DateTime | null;
    /**
     * Номер платежного поручения
     */
    numberPayment?: string | null;
    /**
     * Ответственный
     */
    responsible?: string | null;
    /**
     * Идентификатор данных клиента
     */
    personId?: number | null;
    /**
     * Идентификатор Исполнительного производства
     */
    enforcementDealId?: number | null;
    /**
     * Id контакта
     */
    contactId?: number | null;
    contact?: CollectionContactDto;
}


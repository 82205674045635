import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Nullable } from '@lib-utils';
import {
  CollectionCourtDealDto,
  CollectionCourtDealDtoPageResultData,
  CollectionCourtDealDtoResponseData,
  CollectionCourtDealRequest,
  CollectionEnforcementDealDto,
  CollectionEnforcementDealDtoPageResultData,
  CollectionEnforcementDealDtoResponseData,
  CollectionEnforcementDealRequest,
  CrmCollectionApiService,
  Int64ResponseData,
} from '@lib-collection/api';

export abstract class ProceedingService {
  protected crmCollectionApiService = inject(CrmCollectionApiService);

  abstract getProceedings(
    personId: Nullable<number>,
    page?: number,
    perPage?: number,
  ): Observable<CollectionCourtDealDtoPageResultData | CollectionEnforcementDealDtoPageResultData>;

  abstract addProceeding(
    dto: CollectionEnforcementDealRequest | CollectionCourtDealRequest,
  ): Observable<CollectionEnforcementDealDtoResponseData | CollectionCourtDealDtoResponseData>;

  abstract getProceeding(id: number): Observable<CollectionEnforcementDealDto | CollectionCourtDealDto>;

  abstract updateProceeding(
    id: number,
    item: CollectionEnforcementDealDto | CollectionCourtDealDto,
  ): Observable<Int64ResponseData>;

  abstract removeProceeding(id: number): Observable<number>;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiIslandModule } from '@taiga-ui/kit';
import { ButtonModule } from '@lib-widgets/core';
import {
  ReactiveInputDateModule,
  ReactiveInputModule,
  ReactiveInputNumberModule,
  ReactiveSelectModule,
  ReactiveTextAreaModule,
} from '@lib-widgets/reactive-fields';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { EditProceedingsComponent } from './edit-proceedings.component';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    ReactiveInputDateModule,
    ReactiveInputNumberModule,
    ReactiveInputModule,
    ReactiveTextAreaModule,
    RequestWrapperModule,
    TuiIslandModule,
    ReactiveSelectModule,
  ],
  declarations: [EditProceedingsComponent],
})
export class EditProceedingsModule {}

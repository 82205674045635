/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateTime } from '@lib-utils';


export interface OutsourcingBpRequestModel { 
    /**
     * Идентификатор
     */
    id?: number;
    bank?: string | null;
    creditContractNumber?: string | null;
    dateTransferredToKa?: DateTime | null;
    dateCancelledToKa?: DateTime | null;
    stateDecoding?: string | null;
    ka?: string | null;
}


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductType } from './productType';
import { SegmentPriorityType } from './segmentPriorityType';


/**
 * Коллекшн сегмент
 */
export interface CollectionSegmentRequest { 
    /**
     * Наименование
     */
    name?: string | null;
    /**
     * SQL
     */
    sql?: string | null;
    priorityType?: SegmentPriorityType;
    /**
     * Дней просрочки от
     */
    arrearsDayFrom?: number | null;
    /**
     * Дней просрочки до
     */
    arrearsDayTo?: number | null;
    /**
     * Сумма просрочки от
     */
    arrearsSumFrom?: number | null;
    /**
     * Сумма просрочки до
     */
    arrearsSumTo?: number | null;
    /**
     * Тип списка обзвона
     */
    callListId?: number | null;
    /**
     * Идентификатор результата последней активности.
     */
    lastResultActivityId?: number | null;
    /**
     * Банкротство
     */
    isBankruptcy?: boolean;
    /**
     * Смерть
     */
    isDeath?: boolean;
    /**
     * Отказ от взаимодействия
     */
    isInteractionRefusal?: boolean;
    /**
     * Пересегментация
     */
    isResegmentation?: boolean;
    /**
     * Уровень взыскания
     */
    levelId?: number | null;
    /**
     * Передача в КА (коллекторское агенство)
     */
    isTransferToCollectionAgency?: boolean;
    /**
     * Коллекторское агенство
     */
    collectionAgencyName?: string | null;
    /**
     * Продукты
     */
    products?: Array<ProductType> | null;
}


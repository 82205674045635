import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ConfigService } from '@lib-config';
import { BreadcrumbComponent, PrivacyPolicyComponent } from '@lib-widgets/core';
import { getBasePath } from '@lib-collection/utils';
import { getLogout$ } from '@lib-mortgage/features/authorization';
import { ExpandableMenuComponent, MenuItem, ToolbarModule } from '@lib-mortgage/widgets/layout';
import { environment } from '../environments/environment';
import { ProceedingsListModule } from './widgets/proceedings-list';
import { EditProceedingsModule } from './widgets/proceedings-list/edit';

@Component({
  standalone: true,
  selector: 'app-crm-collection-app-component',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ToolbarModule,
    ProceedingsListModule,
    EditProceedingsModule,
    ExpandableMenuComponent,
    PrivacyPolicyComponent,
    BreadcrumbComponent,
    RouterOutlet,
  ],
})
export class AppComponent {
  readonly hostUrl = inject(ConfigService).hostUrl;

  readonly basePath = getBasePath();
  readonly collectionVersion = environment.frontVersion;
  readonly menuItems: MenuItem[] = [
    {
      label: 'Кредитные договора',
      icon: 'tuiIconFile',
      link: `${this.basePath}/contracts`,
      permission: 'crmContractList',
    },
    {
      label: 'Журнал отчётов',
      icon: 'tuiIconFileText',
      link: `${this.basePath}/reports`,
    },
    {
      label: 'Аутсорс',
      icon: 'tuiIconExternalLink',
      children: [
        {
          label: 'Кредиты',
          link: `${this.basePath}/outsource/credits`,
        },
        {
          label: 'Файлы агентств',
          link: `${this.basePath}/outsource/agency-files`,
        },
      ],
    },
    // TODO: скрыто в рамках FNCOL-108
    // {
    //   label: 'Списки обзвона',
    //   icon: 'tuiIconPhoneCall',
    //   link: `${this.basePath}/call-lists`,
    //   permission: 'crmCallListList',
    // },
    // {
    //   label: 'Коллекшн сегменты',
    //   icon: 'tuiIconDatabase',
    //   link: `${this.basePath}/segments`,
    //   permission: 'crmSegmentList',
    // },
    // {
    //   label: 'Уведомления',
    //   icon: 'tuiIconMessageCircle',
    //   permission: 'crmNotificationList',
    //   children: [
    //     {
    //       label: 'Шаблоны',
    //       link: `${this.basePath}/templates`,
    //       permission: 'crmTemplateList',
    //     },
    //     {
    //       label: 'Стратегии автоинструментов',
    //       link: `${this.basePath}/strategies`,
    //       permission: 'crmStrategyList',
    //     },
    //   ],
    // },
    // {
    //   label: 'Результаты активности',
    //   icon: 'tuiIconUserCheck',
    //   link: `${this.basePath}/result-activities`,
    //   permission: 'crmResultActivityCreate',
    // },
    // {
    //   label: 'Уровни взыскания',
    //   icon: 'tuiIconLayers',
    //   link: `${this.basePath}/penalty-levels`,
    //   permission: 'crmLevelList',
    // },
    // {
    //   label: 'Черный список',
    //   icon: 'tuiIconPhoneOff',
    //   link: `${this.basePath}/black-lists`,
    //   permission: 'crmBlackListList',
    // },
    // {
    //   label: 'Исполнительные производства',
    //   icon: 'tuiIconZap',
    //   link: `${this.basePath}/enforcement-proceedings`,
    //   permission: 'crmEnforcementProceedingsList',
    // },
    // {
    //   label: 'Судебные производства',
    //   icon: 'tuiIconFlag',
    //   link: `${this.basePath}/judicial-proceedings`,
    //   permission: 'crmJudicialProceedingsList',
    // },
  ];

  logout$ = getLogout$();
}

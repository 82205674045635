/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>Fnip.Collection.Utils.Enums.CrmCollection.CollectionReportBuilderTaskStatus</b></p><p>Members:</p> <ul> <p><li><i>New</i> - Новая задача генерации.</li></p> <p><li><i>InProgres</i> - Генерация отчета началась.</li></p> <p><li><i>Done</i> - Отчет готов.</li></p> <p><li><i>Fail</i> - Ошибка генерации отчета.</li></p> </ul> 
 */
export type CollectionReportBuilderTaskStatus = 'New' | 'InProgres' | 'Done' | 'Fail';

export const CollectionReportBuilderTaskStatus = {
    New: 'New' as CollectionReportBuilderTaskStatus,
    InProgres: 'InProgres' as CollectionReportBuilderTaskStatus,
    Done: 'Done' as CollectionReportBuilderTaskStatus,
    Fail: 'Fail' as CollectionReportBuilderTaskStatus
};


import { CollectionContractFileType } from '@lib-collection/api';

export const ContractFileTypeMap: Map<CollectionContractFileType, string> = new Map([
  [CollectionContractFileType.ArestAct, 'Акт о наложении ареста'],
  [CollectionContractFileType.ActDrz, 'Акт о состоянии залогового имущества (ДРЗ)'],
  [CollectionContractFileType.ActDrzElse, 'Акт о состоянии залогового имущества (ДРЗ/Отступное)'],
  [CollectionContractFileType.ActImha, 'Акт о состоянии залогового имущества (ИМХА)'],
  [CollectionContractFileType.ActZalog, 'Акт о состоянии залогового имущества (Отступное)'],
  [CollectionContractFileType.PowerOfAttorney, 'Доверенность о предоставлении интересов клиента представителем'],
  [CollectionContractFileType.PurchaseSaleAgreement, 'Договор купли-продажи ТС'],
  [CollectionContractFileType.ApplicationBankrupt, 'Заявление гражданина о признании банкротом'],
  [CollectionContractFileType.ApplicationIgnore, 'Заявление клиента об отказе от взаимодействия'],
  [CollectionContractFileType.ApplicationZalog, 'Заявление на реализацию залога'],
  [CollectionContractFileType.ApplicationAgreement, 'Заявление о согласовании цены реализации'],
  [CollectionContractFileType.ApplicationInheritance, 'Заявление об отказе от наследства'],
  [CollectionContractFileType.ApplicationElse, 'Заявление об отступном'],
  [CollectionContractFileType.ApplicationInsurance, 'Заявление о страховой выплате по ОСАГО/Каско'],
  [CollectionContractFileType.DeathDoc, 'Иные документы о смерти'],
  [CollectionContractFileType.Payment, 'Квитанция об оплате'],
  [CollectionContractFileType.Ovd, 'Материалы ОВД в рамках ИМХА'],
  [CollectionContractFileType.Fssp, 'Определение ФССП'],
  [CollectionContractFileType.PriceZalog, 'Оценка стоимости предмета залога'],
  [CollectionContractFileType.ActFssp, 'Постановление ФССП о передаче нереализованного имущества'],
  [CollectionContractFileType.Ugon, 'Постановление о возбуждении уголовного дела и принятия его к производству (Угон)'],
  [CollectionContractFileType.Arest, 'Постановление о наложении ареста'],
  [CollectionContractFileType.ArestSud, 'Постановление суда об аресте'],
  [CollectionContractFileType.OfferFssp, 'Предложение ФССП оставить нереализованное имущество'],
  [CollectionContractFileType.ResultZalog, 'Результаты торгов залога'],
  [CollectionContractFileType.DecisionSud, 'Решение суда о лишении свободы'],
  [CollectionContractFileType.CertificateInheritance, 'Свидетельство о праве на наследство'],
  [CollectionContractFileType.CertificateDeath, 'Свидетельство о смерти'],
  [CollectionContractFileType.Note, 'Служебная записка о принятии на баланс'],
  [CollectionContractFileType.NoteElse, 'Соглашение об отступном'],
  [CollectionContractFileType.CertificateDisability, 'Справка инвалидности (первой группы)'],
  [CollectionContractFileType.NoteDtp, 'Справка о ДТП (с описанием степени повреждений и/или фото)'],
  [CollectionContractFileType.NoteWanted, 'Справка о нахождении в розыске'],
  [CollectionContractFileType.NotificationMvd, 'Талон-уведомление (из МВД)'],
  [CollectionContractFileType.RequirementSud, 'Требование судебного пристава'],
  [CollectionContractFileType.PhotoZalog, 'Фото залогового имущества'],
  [CollectionContractFileType.ApplicationArgDrz, 'Заявка на согласование ДРЗ'],
  [CollectionContractFileType.FsspStay, 'Предложение ФССП оставить не реализованное имущество'],
  [CollectionContractFileType.FsspTransfer, 'Постановление ФССП о передаче не реализованного имущества'],
  [CollectionContractFileType.SudRule, 'Определение суда'],
  [CollectionContractFileType.MvdImha, 'Материалы МВД в рамках ИМХА'],
  [CollectionContractFileType.ActTransferZalog, 'Акт приема-передачи залогового имущества'],
  [CollectionContractFileType.FsspPrice, 'Постановление об оценке ФССП'],
  [CollectionContractFileType.Other, 'Иное'],
]);

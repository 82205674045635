/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientModel } from './clientModel';
import { DateOnly } from '@lib-utils';


/**
 * Кредитный договор
 */
export interface ContractModel { 
    /**
     * Идентификатор
     */
    id?: number;
    contractNumber?: string | null;
    /**
     * Вид кредита
     */
    creditType?: string | null;
    /**
     * Тип проекта
     */
    projectType?: string | null;
    /**
     * ФИО
     */
    coborrowerFio?: string | null;
    /**
     * Дата рождения
     */
    coborrowerDateOfBirth?: DateOnly | null;
    /**
     * Место рождения
     */
    coborrowerBirthPlace?: string | null;
    /**
     * Серия паспорта
     */
    coborrowerSeries?: string | null;
    /**
     * Номер паспорта
     */
    coborrowerNumber?: string | null;
    /**
     * Дата выдачи паспорта
     */
    coborrowerIssuedDate?: DateOnly | null;
    /**
     * Кем выдан Паспорт
     */
    coborrowerIssuedBy?: string | null;
    /**
     * Адрес регистрации
     */
    coborrowerRegistrationAddress?: string | null;
    /**
     * Место жительства
     */
    coborrowerFactAddress?: string | null;
    /**
     * Почтовый адрес
     */
    coborrowerPostAddress?: string | null;
    /**
     * Телефон Мобильный
     */
    coborrowerMobilePhone?: string | null;
    /**
     * Телефон рабочий
     */
    coborrowerJobPhone?: string | null;
    /**
     * Телефон Домашний
     */
    coborrowerPhone?: string | null;
    /**
     * ФИО
     */
    guarantorFio?: string | null;
    /**
     * Дата рождения
     */
    guarantorDateOfBirth?: DateOnly | null;
    /**
     * Место рождения
     */
    guarantorBirthPlace?: string | null;
    /**
     * Серия паспорта
     */
    guarantorSeries?: string | null;
    /**
     * Номер паспорта
     */
    guarantorNumber?: string | null;
    /**
     * Дата выдачи паспорта
     */
    guarantorIssuedDate?: DateOnly | null;
    /**
     * Кем выдан Паспорт
     */
    guarantorIssuedBy?: string | null;
    /**
     * Адрес регистрации
     */
    guarantorRegistrationAddress?: string | null;
    /**
     * Место жительства
     */
    guarantorFactAddress?: string | null;
    /**
     * Почтовый адрес
     */
    guarantorPostAddress?: string | null;
    /**
     * Телефон Мобильный
     */
    guarantorMobilePhone?: string | null;
    /**
     * Телефон рабочий
     */
    guarantorJobPhone?: string | null;
    /**
     * Телефон Домашний
     */
    guarantorPhone?: string | null;
    /**
     * Дата начала кредита
     */
    creditStartDate?: DateOnly | null;
    /**
     * Дата окончания кредита
     */
    creditEndDate?: DateOnly | null;
    /**
     * Дата закрытия кредита
     */
    creditCloseDate?: DateOnly | null;
    /**
     * Сумма выдачи
     */
    creditTotalSum?: number | null;
    /**
     * Валюта кредита
     */
    currency?: string | null;
    /**
     * Сумма аннуитета
     */
    sumAnnuity?: number | null;
    /**
     * Дата аннуитета
     */
    dayAnnuity?: number | null;
    /**
     * Подразделение
     */
    department?: string | null;
    /**
     * Залог авто:Модель и марка автомобиля
     */
    autoModel?: string | null;
    /**
     * Залог авто: VIN
     */
    autoVin?: string | null;
    /**
     * Залог авто: год производства автомобиля
     */
    autoYear?: string | null;
    /**
     * Залог авто: цвет автомобиля
     */
    autoColor?: string | null;
    /**
     * Залог авто: номер ПТС
     */
    autoPts?: string | null;
    /**
     * Дата образования задолженности по основному долгу
     */
    overdueDate?: DateOnly | null;
    /**
     * Дата выноса процентов
     */
    percentDate?: DateOnly | null;
    /**
     * Дата начисления пеней (кредит)
     */
    peneCreditDate?: DateOnly | null;
    /**
     * Дата начисления пеней (проценты)
     */
    penePercentDate?: DateOnly | null;
    /**
     * Общая задолженность, руб.
     */
    debtSumRub?: number | null;
    /**
     * Просроченная задолженность, руб.
     */
    overdueDebt?: number | null;
    /**
     * Идентификатор данных клиента из выгрузки csv по полю \"Кредиты # ID\"
     */
    externalId?: number | null;
    /**
     * Идентификатор данных клиента
     */
    clientId?: number | null;
    client?: ClientModel;
    /**
     * Срок просрочки по FIFO
     */
    daysDelayFifo?: number | null;
    /**
     * Срок просрочки по LIFO
     */
    daysDelayLifo?: number | null;
    /**
     * Программа кредитования
     */
    creditProgram?: string | null;
}


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Вся информация по работе с залогом (поиск/изъятие/реализация/место хранения залога и пр).
 */
export interface OutsourcingDrzModel { 
    /**
     * Идентификатор
     */
    id?: number;
    /**
     * Идентификатор банка
     */
    bankId?: string | null;
    /**
     * Рсшифровка идентификатора банка
     */
    bankIdDescription?: string | null;
    /**
     * Номер контракта
     */
    creditContractNumber?: string | null;
    /**
     * Регион
     */
    region?: string | null;
    /**
     * Фамилия клиента
     */
    surname?: string | null;
    /**
     * Имя клиента
     */
    name?: string | null;
    /**
     * Отчество клиента
     */
    patronymic?: string | null;
    /**
     * VIN номер автомобиля
     */
    vin?: string | null;
    /**
     * Марка автомобиля
     */
    brand?: string | null;
    /**
     * Модель автомобиля
     */
    model?: string | null;
    /**
     * Год выпуска автомобиля
     */
    issuedYear?: string | null;
    /**
     * 
     */
    withdrawalDate?: string | null;
    /**
     * 
     */
    transferDate?: string | null;
    /**
     * 
     */
    evalDate?: string | null;
    /**
     * 
     */
    websiteDate?: string | null;
    /**
     * 
     */
    websitePrice?: string | null;
    /**
     * 
     */
    marketPrice?: string | null;
    /**
     * 
     */
    knockdownPrice?: string | null;
    /**
     * 
     */
    discount?: string | null;
    /**
     * 
     */
    retryDiscount?: string | null;
    /**
     * 
     */
    ipDate?: string | null;
    /**
     * 
     */
    seizureDate?: string | null;
    /**
     * 
     */
    previousState?: string | null;
    /**
     * 
     */
    state?: string | null;
    /**
     * 
     */
    saleBalanceDate?: string | null;
    /**
     * 
     */
    saleBalancePrice?: string | null;
    /**
     * Комментарий
     */
    comment?: string | null;
    /**
     * Адрес парковки автомобиля
     */
    parkingAddress?: string | null;
    /**
     * Ссылка на
     */
    link?: string | null;
}


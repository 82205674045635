<fnip-request-wrapper [request$]="proceeding$" (response)="$event && patchFormWith($event)">
  <tui-island class="island">
    <h2 class="island__header">{{ header }}</h2>
    <div class="island__body">
      <div class="columns columns__item-flex">
        <div class="column">
          <fnip-reactive-select
            *ngIf="proceedingType === 'enforcement'"
            fieldId="courtDealId"
            label="Судебное производство"
            [requiredIf]="true"
            [control]="form.controls.courtDealId"
            [options]="courtDeals$"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input-number
            fieldId="clientId"
            label="ID клиента"
            [control]="form.controls.clientId"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input-number
            fieldId="contractId"
            label="ID договора"
            [control]="form.controls.contractId"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input-number
            fieldId="debtId"
            label="ID долга"
            [control]="form.controls.debtId"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-select
            fieldId="status"
            label="Статус"
            [control]="form.controls.status"
            [options]="statuses"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input
            fieldId="feedMethod"
            label="Способ подачи"
            [control]="form.controls.feedMethod"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input
            fieldId="number"
            label="Номер дела в суде"
            [control]="form.controls.number"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input-date
            fieldId="startDate"
            label="Дата возбуждения дела"
            [control]="form.controls.startDate"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input-date
            fieldId="stopDate"
            label="Дата приостановления ИП"
            [control]="form.controls.endDate"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input-date
            fieldId="courtSubmitDate"
            label="Дата подачи в суд"
            [control]="form.controls.courtSubmitDate"
            [textfieldCleaner]="true"
          />
        </div>
        <div class="column">
          <fnip-reactive-input-date
            fieldId="contractTerminationDate"
            label="Дата расторжения договора"
            [control]="form.controls.contractTerminationDate"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input-date
            fieldId="decisionDate"
            label="Дата вынесения решения"
            [control]="form.controls.decisionDate"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input-date
            fieldId="effectiveDate"
            label="Дата вступления в силу"
            [control]="form.controls.effectiveDate"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input-date
            fieldId="paymentDutyDate"
            label="Дата оплаты пошлины"
            [control]="form.controls.paymentDutyDate"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input-date
            fieldId="requestDate"
            label="Дата направления требования"
            [control]="form.controls.requestDate"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input-date
            fieldId="overdueDate"
            label="Дата вынесения всей суммы на просрочку"
            [control]="form.controls.overdueDate"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input-date
            fieldId="enforcementDate"
            label="Дата получения исполнительного листа"
            [control]="form.controls.enforcementDate"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input-date
            fieldId="settlementDate"
            label="Дата мирового соглашения"
            [control]="form.controls.settlementDate"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input-date
            fieldId="decisionClaimDate"
            label="Дата решения по иску"
            [control]="form.controls.decisionClaimDate"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-text-area
            fieldId="comment"
            label="Комментарий"
            [control]="form.controls.comment"
            [textfieldCleaner]="true"
          />
        </div>
        <div class="column">
          <fnip-reactive-text-area
            fieldId="bank"
            label="Банк"
            [control]="form.controls.bank"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-text-area
            fieldId="court"
            label="Название суда"
            [control]="form.controls.court"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input-number
            fieldId="sum"
            label="Сумма по решению суда"
            [control]="form.controls.sum"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input-number
            fieldId="approvedDuty"
            label="Утвержденная пошлина"
            [control]="form.controls.approvedDuty"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input
            fieldId="result"
            label="Результат по делу"
            [control]="form.controls.result"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input
            fieldId="incomingNumber"
            label="Входящий номер"
            [control]="form.controls.incomingNumber"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input-number
            fieldId="sumSettlement"
            label="Сумма мирового соглашения"
            [control]="form.controls.sumSettlement"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input
            fieldId="numberPayment"
            label="Номер платежного поручения"
            [control]="form.controls.numberPayment"
            [textfieldCleaner]="true"
          />
          <fnip-reactive-input
            fieldId="responsible"
            label="Ответственный"
            [control]="form.controls.responsible"
            [textfieldCleaner]="true"
          />
        </div>
      </div>
    </div>
    <div class="island__actions">
      <fnip-button
        appearance="primary"
        label="Сохранить"
        class="action"
        [isDisabled]="form.invalid"
        [actionCallback$]="getSaveCallback$"
      />
      <fnip-button appearance="flat" label="Отменить" class="action" [actionCallback$]="getCancelCallback$" />
    </div>
  </tui-island>
</fnip-request-wrapper>

/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserInfoDto } from './userInfoDto';
import { DateTime } from '@lib-utils';


/**
 * Комментарии
 */
export interface ContractCommentDto { 
    id?: number;
    /**
     * Комментарий
     */
    comment?: string | null;
    creator?: UserInfoDto;
    /**
     * Дата создания сущности
     */
    created?: DateTime | null;
}


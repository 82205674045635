/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CollectionCallListDto } from './collectionCallListDto';
import { ProductType } from './productType';
import { SegmentPriorityType } from './segmentPriorityType';
import { CollectionResultActivityDto } from './collectionResultActivityDto';
import { CollectionTemplateDto } from './collectionTemplateDto';


/**
 * Стратегия автоинструментов
 */
export interface CollectionStrategyDto { 
    id?: number;
    /**
     * Наименование
     */
    name?: string | null;
    /**
     * SQL
     */
    sql?: string | null;
    priorityType?: SegmentPriorityType;
    /**
     * Дней просрочки от
     */
    arrearsDayFrom?: number | null;
    /**
     * Дней просрочки до
     */
    arrearsDayTo?: number | null;
    /**
     * Сумма просрочки от
     */
    arrearsSumFrom?: number | null;
    /**
     * Сумма просрочки до
     */
    arrearsSumTo?: number | null;
    /**
     * Тип списка обзвона
     */
    callListId?: number | null;
    callList?: CollectionCallListDto;
    /**
     * Идентификатор результата последней активности.
     */
    lastResultActivityId?: number | null;
    lastResultActivity?: CollectionResultActivityDto;
    /**
     * Признак активности
     */
    isActive?: boolean;
    /**
     * Результаты активностей
     */
    resultActivityIds?: Array<number> | null;
    /**
     * Результаты активностей
     */
    resultActivities?: Array<CollectionResultActivityDto> | null;
    /**
     * Продукты
     */
    products?: Array<ProductType> | null;
    /**
     * Id шаблона
     */
    templateId?: number | null;
    template?: CollectionTemplateDto;
}


import { RouteTitleData, withCabinetTitle } from '@lib-config';
import { ExtendedRoute, RouteBreadcrumbData } from '@lib-utils';
import { NoPageComponent } from '@lib-widgets/core';
import {
  authorizationGuardFn,
  getAuthorizationRoutes,
  permissionGuardFn,
  RoutePermissionData,
} from '@lib-mortgage/features/authorization';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { ProceedingListComponent } from './widgets/proceedings-list';
import { EditProceedingsComponent } from './widgets/proceedings-list/edit';

export const appRoutes: ExtendedRoute<
  RouteBreadcrumbData &
    RoutePermissionData &
    RouteTitleData & { pageHeader?: string; type?: 'judicial' | 'enforcement' }
>[] = [
  {
    path: '',
    title: 'CRM_COLLECTION.BREADCRUMBS.CRM_COLLECTION',
    component: AppComponent,
    canActivate: [authorizationGuardFn],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'contracts',
      },
      {
        path: 'contracts',
        title: 'CRM_COLLECTION.BREADCRUMBS.CONTRACTS',
        canActivate: [permissionGuardFn],
        loadChildren: () => import('./views/contracts/contracts.module').then((m) => m.ContractsModule),
        data: {
          requiredPermission: 'crmContactList',
          ...withCabinetTitle('CRM_COLLECTION.BREADCRUMBS.CONTRACTS'),
        },
      },
      {
        path: 'call-lists',
        title: 'CRM_COLLECTION.BREADCRUMBS.CALL_LISTS',
        canActivateChild: [permissionGuardFn],
        loadChildren: () => import('./views/call-lists').then((m) => m.CallListsModule),
        data: {
          ...withCabinetTitle('CRM_COLLECTION.BREADCRUMBS.CALL_LISTS'),
        },
      },
      {
        path: 'segments',
        title: 'CRM_COLLECTION.BREADCRUMBS.SEGMENTS',
        canActivateChild: [permissionGuardFn],
        loadChildren: () => import('./views/segments').then((m) => m.SegmentsModule),
        data: {
          ...withCabinetTitle('CRM_COLLECTION.BREADCRUMBS.SEGMENTS'),
        },
      },
      {
        path: 'strategies',
        title: 'CRM_COLLECTION.BREADCRUMBS.STRATEGIES',
        canActivateChild: [permissionGuardFn],
        loadChildren: () => import('./views/strategies').then((m) => m.StrategiesModule),
        data: {
          ...withCabinetTitle('CRM_COLLECTION.BREADCRUMBS.STRATEGIES'),
        },
      },
      {
        path: 'templates',
        title: 'CRM_COLLECTION.BREADCRUMBS.TEMPLATES',
        canActivateChild: [permissionGuardFn],
        loadChildren: () => import('./views/templates').then((m) => m.TemplatesModule),
        data: {
          ...withCabinetTitle('CRM_COLLECTION.BREADCRUMBS.TEMPLATES'),
        },
      },
      {
        path: 'result-activities',
        title: 'CRM_COLLECTION.BREADCRUMBS.RESULT_ACTIVITIES',
        canActivateChild: [permissionGuardFn],
        loadChildren: () => import('./views/result-activities').then((m) => m.ResultActivitiesModule),
        data: {
          ...withCabinetTitle('CRM_COLLECTION.BREADCRUMBS.RESULT_ACTIVITIES'),
        },
      },
      {
        path: 'penalty-levels',
        title: 'CRM_COLLECTION.BREADCRUMBS.PENALTY_LEVELS',
        canActivateChild: [permissionGuardFn],
        loadChildren: () => import('./views/penalty-levels').then((m) => m.PenaltyLevelsModule),
        data: {
          ...withCabinetTitle('CRM_COLLECTION.BREADCRUMBS.PENALTY_LEVELS'),
        },
      },
      {
        path: 'black-lists',
        title: 'CRM_COLLECTION.BREADCRUMBS.BLACK_LISTS',
        canActivateChild: [permissionGuardFn],
        loadChildren: () => import('./views/black-lists').then((m) => m.BlackListsModule),
        data: {
          ...withCabinetTitle('CRM_COLLECTION.BREADCRUMBS.BLACK_LISTS'),
        },
      },
      {
        path: 'enforcement-proceedings',
        title: 'CRM_COLLECTION.BREADCRUMBS.ENFORCEMENT_PROCEEDINGS',
        canActivateChild: [permissionGuardFn],
        data: {
          ...withCabinetTitle('CRM_COLLECTION.BREADCRUMBS.ENFORCEMENT_PROCEEDINGS'),
        },
        children: [
          {
            path: '',
            title: 'Исполнительные производства',
            component: ProceedingListComponent,
            data: {
              pageHeader: 'Исполнительные производства',
              requiredPermission: 'crmEnforcementProceedingsList',
              type: 'enforcement',
            },
          },
          {
            path: 'edit/:proceedingId',
            title: (route) => `Исполнительное производство №${route.params.proceedingId}`,
            component: EditProceedingsComponent,
            data: {
              pageHeader: 'Изменить исполнительное производство',
              type: 'enforcement',
            },
          },
          {
            path: 'new',
            title: 'Добавить исполнительное производство',
            component: EditProceedingsComponent,
            data: {
              pageHeader: 'Добавить исполнительное производство',
              type: 'enforcement',
            },
          },
        ],
      },
      {
        path: 'judicial-proceedings',
        title: 'CRM_COLLECTION.BREADCRUMBS.JUDICIAL_PROCEEDINGS',
        canActivateChild: [permissionGuardFn],
        data: {
          ...withCabinetTitle('CRM_COLLECTION.BREADCRUMBS.JUDICIAL_PROCEEDINGS'),
        },
        children: [
          {
            path: '',
            title: 'Судебные производства',
            component: ProceedingListComponent,
            data: {
              pageHeader: 'Судебные производства',
              requiredPermission: 'crmJudicialProceedingsList',
              type: 'judicial',
            },
          },
          {
            path: 'edit/:proceedingId',
            title: (route) => `Судебное производство №${route.params.proceedingId}`,
            component: EditProceedingsComponent,
            data: {
              pageHeader: 'Изменить судебное производство',
              type: 'judicial',
            },
          },
          {
            path: 'new',
            title: 'Добавить судебное производство',
            component: EditProceedingsComponent,
            data: {
              pageHeader: 'Добавить судебное производство',
              type: 'judicial',
            },
          },
        ],
      },
      {
        path: 'outsource',
        title: 'CRM_COLLECTION.BREADCRUMBS.OUTSOURCE',
        data: {
          ...withCabinetTitle('CRM_COLLECTION.BREADCRUMBS.OUTSOURCE'),
        },
        children: [
          {
            path: '',
            redirectTo: 'credits',
            pathMatch: 'full',
          },
          {
            path: 'credits',
            title: 'CRM_COLLECTION.BREADCRUMBS.CREDITS',
            loadChildren: () => import('./views/credits/credits-routes').then((m) => m.CREDITS_ROUTES),
          },
          {
            path: 'agency-files',
            title: 'CRM_COLLECTION.BREADCRUMBS.AGENCY_FILES',
            loadChildren: () => import('./views/agency-files/agency-files-routes').then((m) => m.AGENCY_FILES_ROUTES),
          },
        ],
      },
      {
        path: 'reports',
        title: 'CRM_COLLECTION.BREADCRUMBS.REPORTS',
        loadChildren: () => import('./views/reports').then((m) => m.ReportsModule),
        data: {
          ...withCabinetTitle('CRM_COLLECTION.BREADCRUMBS.REPORTS'),
        },
      },
    ],
  },
  {
    path: 'auth',
    children: getAuthorizationRoutes(environment.frontVersion, 'CRM Collection'),
  },
  {
    path: 'unsupported-browser',
    title: 'Ваш браузер не поддерживается',
    loadComponent: () => import('@lib-widgets/unsupported-browser').then((m) => m.UnsupportedBrowserComponent),
  },
  {
    path: '**',
    title: 'Страница не найдена',
    component: NoPageComponent,
  },
];

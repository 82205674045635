/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductType } from './productType';


/**
 * Запрос получения списка контрактов на базе SQL
 */
export interface ListContractsBySqlRequest { 
    page?: number;
    perPage?: number;
    /**
     * Sql-запрос
     */
    sql?: string | null;
    /**
     * Дней просрочки от
     */
    arrearsDayFrom?: number | null;
    /**
     * Дней просрочки до
     */
    arrearsDayTo?: number | null;
    /**
     * Сумма просрочки от
     */
    arrearsSumFrom?: number | null;
    /**
     * Сумма просрочки до
     */
    arrearsSumTo?: number | null;
    /**
     * Тип списка обзвона
     */
    callListId?: number | null;
    /**
     * Идентификатор результата последней активности.
     */
    lastResultActivityId?: number | null;
    /**
     * Банкротство
     */
    isBankruptcy?: boolean | null;
    /**
     * Смерть
     */
    isDeath?: boolean | null;
    /**
     * Отказ от взаимодействия
     */
    isInteractionRefusal?: boolean | null;
    /**
     * Пересегментация
     */
    isResegmentation?: boolean | null;
    /**
     * Продукты
     */
    products?: Array<ProductType> | null;
}


import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { CollectionConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { BankDataImporterApiService } from './api/bankDataImporter.service';
import { ClientApiService } from './api/client.service';
import { ContactApiService } from './api/contact.service';
import { ContractApiService } from './api/contract.service';
import { CrmCollectionApiService } from './api/crmCollection.service';
import { OutsourcingApiService } from './api/outsourcing.service';
import { ReportApiService } from './api/report.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    BankDataImporterApiService,
    ClientApiService,
    ContactApiService,
    ContractApiService,
    CrmCollectionApiService,
    OutsourcingApiService,
    ReportApiService ]
})
export class FxCollectionApiModule {
    public static forRoot(configurationFactory: () => CollectionConfiguration): ModuleWithProviders<FxCollectionApiModule> {
        return {
            ngModule: FxCollectionApiModule,
            providers: [ { provide: CollectionConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: FxCollectionApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('FxCollectionApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}

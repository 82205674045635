<fnip-toolbar [application]="'CRM Collection'" [hasTranslation]="true" [logoutCallBack$]="logout$" />

<div class="d-flex gap-7">
  <fnip-expandable-menu [items]="menuItems" />
  <div class="w-100 d-flex flex-column">
    <fnip-breadcrumb class="my-5" [hostUrl]="hostUrl" hasTranslations />

    <div class="pr-10">
      <router-outlet />
    </div>
    <fnip-privacy-policy appName="CRM Collection" class="mt-auto pt-6" [version]="collectionVersion" />
  </div>
</div>

/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Техническая информация об ошибке.
 */
export interface TechnicalError { 
    /**
     * Тип исключения.
     */
    exceptionType?: string | null;
    /**
     * Сообщение.
     */
    message?: string | null;
}


<fnip-filter
  [class.mb-2]="fromContract"
  [class.mb-5]="!fromContract"
  [header]="header"
  [actionsTemplate]="actionsTemplate"
/>
<ng-template #actionsTemplate>
  <fnip-button
    appearance="primary"
    icon="tuiIconPlus"
    label="Создать"
    btnRouterLink="new"
    [btnRouterLink]="getCreateRoute | executeWith: fromContract : proceedingType"
    [btnRouterQueryParams]="{ personId }"
  />
</ng-template>

<fnip-grid [rowData]="getProceedings$ | executeWith: proceedingListService : personId" [gridOptions]="gridOptions" />

/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>Fnip.Collection.Utils.Enums.CallResultType</b></p><p>Members:</p> <ul> <p><li><i>CallBack</i> - Перезвонить</li></p> <p><li><i>NoAnswer</i> - Не отвечает</li></p> <p><li><i>PhoneUnavailable</i> - Телефон не доступен</li></p> <p><li><i>PromiseToPay</i> - Обещание оплатить</li></p> <p><li><i>RefusalToPay</i> - Отказ от оплаты</li></p> <p><li><i>RefusalToInteract</i> - Отказ от взаимодействия</li></p> <p><li><i>AdditionalInfoReceived</i> - Получена доп информация</li></p> <p><li><i>PromiseControl</i> - Контроль обещания</li></p> <p><li><i>DrzRequest</i> - Запрос на ДРЗ</li></p> <p><li><i>WillApplyForRKorKk</i> - Подаст заявку на РК/КК</li></p> <p><li><i>AppliedForRKorKk</i> - Заявка на РК/КК подана</li></p> </ul> 
 */
export type CallResultType = 'CallBack' | 'NoAnswer' | 'PhoneUnavailable' | 'PromiseToPay' | 'RefusalToPay' | 'RefusalToInteract' | 'AdditionalInfoReceived' | 'PromiseControl' | 'DrzRequest' | 'WillApplyForRKorKk' | 'AppliedForRKorKk';

export const CallResultType = {
    CallBack: 'CallBack' as CallResultType,
    NoAnswer: 'NoAnswer' as CallResultType,
    PhoneUnavailable: 'PhoneUnavailable' as CallResultType,
    PromiseToPay: 'PromiseToPay' as CallResultType,
    RefusalToPay: 'RefusalToPay' as CallResultType,
    RefusalToInteract: 'RefusalToInteract' as CallResultType,
    AdditionalInfoReceived: 'AdditionalInfoReceived' as CallResultType,
    PromiseControl: 'PromiseControl' as CallResultType,
    DrzRequest: 'DrzRequest' as CallResultType,
    WillApplyForRKorKk: 'WillApplyForRKorKk' as CallResultType,
    AppliedForRKorKk: 'AppliedForRKorKk' as CallResultType
};


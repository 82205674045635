/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientModel } from './clientModel';
import { OverdueReasonType } from './overdueReasonType';
import { TotalResultType } from './totalResultType';
import { ActivityType } from './activityType';
import { ActivityContractDto } from './activityContractDto';
import { DateTime } from '@lib-utils';


/**
 * Активность
 */
export interface ActivityDto { 
    id?: number;
    overdueReasonType?: OverdueReasonType;
    /**
     * Обещанная сумма оплаты
     */
    promiseSum?: number | null;
    /**
     * Обещанная дата оплаты
     */
    promiseDate?: DateTime | null;
    activityType?: ActivityType;
    /**
     * Дата контакта
     */
    contactDate?: DateTime | null;
    /**
     * Следующая дата контакта
     */
    nextContactDate?: DateTime | null;
    /**
     * Комментарий
     */
    comment?: string | null;
    totalResultType?: TotalResultType;
    /**
     * Клиент
     */
    clientId?: number | null;
    client?: ClientModel;
    /**
     * Информация по кредитам
     */
    contracts?: Array<ActivityContractDto> | null;
}


import { of } from 'rxjs';
import { Nullable } from '@lib-utils';
import { GridGetDataCallback } from '@lib-widgets/grid';

/* eslint-disable @typescript-eslint/no-unused-vars */
export function mockGridDataCallback(_contractId: Nullable<number>): GridGetDataCallback {
  return () =>
    of({
      data: [],
    });
}
/* eslint-enable @typescript-eslint/no-unused-vars */

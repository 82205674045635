/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>Fnip.Collection.Utils.Enums.OverdueReasonType</b></p><p>Members:</p> <ul> <p><li><i>PaymentBeforeCall</i> - Внес деньги до звонка</li></p> <p><li><i>Insolvency</i> - Неплатежеспособность</li></p> <p><li><i>Refusal</i> - Отказ</li></p> <p><li><i>LoanNotForSelf</i> - Брал кредит не для себя</li></p> <p><li><i>Fraud</i> - Мошенничество</li></p> <p><li><i>SalaryDelay</i> - Задержка ЗП</li></p> <p><li><i>Fired</i> - Уволен</li></p> <p><li><i>PartialPayment</i> - Частичная оплата</li></p> <p><li><i>ReceiptError</i> - Ошибка в квитанции</li></p> <p><li><i>PaymentFailed</i> - Не прошел платеж</li></p> <p><li><i>AccountArrested</i> - Арест счетов</li></p> </ul> 
 */
export type OverdueReasonType = 'PaymentBeforeCall' | 'Insolvency' | 'Refusal' | 'LoanNotForSelf' | 'Fraud' | 'SalaryDelay' | 'Fired' | 'PartialPayment' | 'ReceiptError' | 'PaymentFailed' | 'AccountArrested';

export const OverdueReasonType = {
    PaymentBeforeCall: 'PaymentBeforeCall' as OverdueReasonType,
    Insolvency: 'Insolvency' as OverdueReasonType,
    Refusal: 'Refusal' as OverdueReasonType,
    LoanNotForSelf: 'LoanNotForSelf' as OverdueReasonType,
    Fraud: 'Fraud' as OverdueReasonType,
    SalaryDelay: 'SalaryDelay' as OverdueReasonType,
    Fired: 'Fired' as OverdueReasonType,
    PartialPayment: 'PartialPayment' as OverdueReasonType,
    ReceiptError: 'ReceiptError' as OverdueReasonType,
    PaymentFailed: 'PaymentFailed' as OverdueReasonType,
    AccountArrested: 'AccountArrested' as OverdueReasonType
};


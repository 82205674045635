import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiFormatNumberPipeModule } from '@taiga-ui/core';
import { TuiPaginationModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { FilterModule } from '@lib-widgets/filter';
import { GridModule } from '@lib-widgets/grid';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { ProceedingListComponent } from './proceeding-list.component';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    ExecuteWithPipeModule,
    RequestWrapperModule,
    RouterModule,
    TuiFormatNumberPipeModule,
    TuiLetModule,
    TuiPaginationModule,
    GridModule,
    FilterModule,
  ],
  declarations: [ProceedingListComponent],
  exports: [ProceedingListComponent],
})
export class ProceedingsListModule {}

/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { CollectionResultActivityModel } from './collectionResultActivityModel';
import { ProductType } from './productType';
import { CollectionLevelModel } from './collectionLevelModel';
import { SegmentPriorityType } from './segmentPriorityType';
import { CollectionCallListModel } from './collectionCallListModel';
import { DateTime } from '@lib-utils';


/**
 * Коллекшн сегмент
 */
export interface CollectionSegmentModel { 
    /**
     * Идентификатор
     */
    id?: number;
    /**
     * Идентификатор пользователя, создавшего сущность
     */
    creatorId?: number;
    creator?: UserModel;
    /**
     * Дата создания сущности
     */
    created?: DateTime;
    /**
     * Идентификатор пользователя, обновившего сущность
     */
    updatorId?: number;
    updator?: UserModel;
    /**
     * Дата обновления сущность
     */
    updated?: DateTime;
    /**
     * Наименование
     */
    name?: string | null;
    /**
     * SQL
     */
    sql?: string | null;
    priorityType?: SegmentPriorityType;
    /**
     * Дней просрочки от
     */
    arrearsDayFrom?: number | null;
    /**
     * Дней просрочки до
     */
    arrearsDayTo?: number | null;
    /**
     * Сумма просрочки от
     */
    arrearsSumFrom?: number | null;
    /**
     * Сумма просрочки до
     */
    arrearsSumTo?: number | null;
    /**
     * Тип списка обзвона
     */
    callListId?: number | null;
    callList?: CollectionCallListModel;
    /**
     * Идентификатор результата последней активности.
     */
    lastResultActivityId?: number | null;
    lastResultActivity?: CollectionResultActivityModel;
    /**
     * Банкротство
     */
    isBankruptcy?: boolean;
    /**
     * Смерть
     */
    isDeath?: boolean;
    /**
     * Отказ от взаимодействия
     */
    isInteractionRefusal?: boolean;
    /**
     * Пересегментация
     */
    isResegmentation?: boolean;
    /**
     * Уровень взыскания
     */
    levelId?: number | null;
    /**
     * Передача в КА (коллекторское агенство)
     */
    isTransferToCollectionAgency?: boolean;
    /**
     * Коллекторское агенство
     */
    collectionAgencyName?: string | null;
    level?: CollectionLevelModel;
    /**
     * Продукты
     */
    products?: Array<ProductType> | null;
}


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>Fnip.Collection.Utils.Enums.ActivityType</b></p><p>Members:</p> <ul> <p><li><i>OutgoingCall</i> - Исходящий звонок</li></p> <p><li><i>IncomingCall</i> - Входящий звонок</li></p> <p><li><i>Departure</i> - Выезд к клиенту</li></p> </ul> 
 */
export type ActivityType = 'OutgoingCall' | 'IncomingCall' | 'Departure';

export const ActivityType = {
    OutgoingCall: 'OutgoingCall' as ActivityType,
    IncomingCall: 'IncomingCall' as ActivityType,
    Departure: 'Departure' as ActivityType
};


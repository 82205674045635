/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Гашения по переданным договорам в период нахождения актива в работе
 */
export interface OutsourcingPaymentModel { 
    /**
     * Номер договора
     */
    creditContractNumber?: string | null;
    /**
     * ФИО клиента
     */
    clientName?: string | null;
    /**
     * Банк
     */
    bank?: string | null;
    /**
     * Количество дней в просрочке
     */
    overdueDays?: string | null;
    /**
     * Сумма платежа в рублях
     */
    paymentAmount?: string | null;
    /**
     * Валюта
     */
    currency?: string | null;
    /**
     * Дата проводки
     */
    paymentDate?: string | null;
    /**
     * Тип гашения
     */
    paymentType?: string | null;
    /**
     * Дата выгрузки
     */
    uploadDate?: string | null;
}


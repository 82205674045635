/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>Fnip.Collection.Utils.Enums.OutsourcingFileType</b></p><p>Members:</p> <ul> <p><li><i>Drz</i> - Файл с информацией по работе с залогом (поиск/изъятие/реализация/место хранения залога и пр)</li></p> <p><li><i>Sms</i> - Смс и комментарии по взаимодействию с должником</li></p> <p><li><i>Calls</i> - Звонки и комментарии по взаимодействию с должником</li></p> <p><li><i>Departure</i> - Выезды и комментарии по взаимодействию с должником</li></p> </ul> 
 */
export type OutsourcingFileType = 'Drz' | 'Sms' | 'Calls' | 'Departure';

export const OutsourcingFileType = {
    Drz: 'Drz' as OutsourcingFileType,
    Sms: 'Sms' as OutsourcingFileType,
    Calls: 'Calls' as OutsourcingFileType,
    Departure: 'Departure' as OutsourcingFileType
};


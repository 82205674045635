/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>Fnip.Collection.Utils.Enums.CrmCollection.CollectionReportType</b></p><p>Members:</p> <ul> <p><li><i>GeneralCollectionReport</i> - Общий отчет по Collection</li></p> <p><li><i>LatestActivitiesReport</i> - Отчет по последним активностям</li></p> <p><li><i>ExceptionOrCollateralModificationReport</i> - Отчет об исключении или изменении залога</li></p> <p><li><i>CreditAgreementOutsourcedReport</i> - Отчет по КД в аутсорсе</li></p> <p><li><i>Legal</i> - Отчет \"Легал\"</li></p> </ul> 
 */
export type CollectionReportType = 'GeneralCollectionReport' | 'LatestActivitiesReport' | 'ExceptionOrCollateralModificationReport' | 'CreditAgreementOutsourcedReport' | 'Legal';

export const CollectionReportType = {
    GeneralCollectionReport: 'GeneralCollectionReport' as CollectionReportType,
    LatestActivitiesReport: 'LatestActivitiesReport' as CollectionReportType,
    ExceptionOrCollateralModificationReport: 'ExceptionOrCollateralModificationReport' as CollectionReportType,
    CreditAgreementOutsourcedReport: 'CreditAgreementOutsourcedReport' as CollectionReportType,
    Legal: 'Legal' as CollectionReportType
};


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateOnly } from '@lib-utils';


/**
 * Данные клиента
 */
export interface CollectionPersonDto { 
    id?: number;
    /**
     * Имя
     */
    firstName?: string | null;
    /**
     * Отчество
     */
    patronymic?: string | null;
    /**
     * Фамилия
     */
    lastName?: string | null;
    /**
     * Предыдущая фамилия
     */
    previousLastName?: string | null;
    /**
     * Пол
     */
    sex?: string | null;
    /**
     * Образование
     */
    education?: string | null;
    /**
     * Семейное положение
     */
    familyStatus?: string | null;
    /**
     * Дата рождения
     */
    dateOfBirth?: DateOnly | null;
    /**
     * Место рождения
     */
    birthPlace?: string | null;
    /**
     * Дата смерти
     */
    deathDay?: DateOnly | null;
    /**
     * Тип клиента
     */
    personType?: string | null;
    /**
     * Дата получения отказа от взаимодействия
     */
    stopInteractionDate?: DateOnly | null;
    /**
     * Гражданство
     */
    citizenship?: string | null;
    /**
     * Отказ от взаимодействия
     */
    interactionRefusal?: string | null;
    /**
     * Признак \"Получен отказ от взаимодествия\"
     */
    stopInteraction?: string | null;
    /**
     * Текущий сегмент
     */
    segment?: string | null;
    /**
     * Важность персоны
     */
    vip?: number | null;
}


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>Fnip.Collection.Utils.Enums.CourtStatusType</b></p><p>Members:</p> <ul> <p><li><i>Unknown</i> - Неопределен</li></p> <p><li><i>DocumentRequest</i> - Запрос документов</li></p> <p><li><i>DocumentAccept</i> - Документы получены</li></p> <p><li><i>HaveOrder</i> - Есть приказ</li></p> <p><li><i>HaveLawsuit</i> - Есть иск</li></p> <p><li><i>Discontinued</i> - Прекращено</li></p> </ul> 
 */
export type CourtStatusType = 'Unknown' | 'DocumentRequest' | 'DocumentAccept' | 'HaveOrder' | 'HaveLawsuit' | 'Discontinued';

export const CourtStatusType = {
    Unknown: 'Unknown' as CourtStatusType,
    DocumentRequest: 'DocumentRequest' as CourtStatusType,
    DocumentAccept: 'DocumentAccept' as CourtStatusType,
    HaveOrder: 'HaveOrder' as CourtStatusType,
    HaveLawsuit: 'HaveLawsuit' as CourtStatusType,
    Discontinued: 'Discontinued' as CourtStatusType
};


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContactType } from './contactType';
import { DateOnly } from '@lib-utils';


/**
 * Контакт
 */
export interface CollectionContactDto { 
    id?: number;
    /**
     * Контактное лицо (ФИО)
     */
    contactPerson?: string | null;
    contactType?: ContactType;
    /**
     * Номер телефона
     */
    phoneNumber?: string | null;
    /**
     * Мобильный номер телефона
     */
    mobilePhoneNumber?: string | null;
    /**
     * Рабочий номер телефона
     */
    jobPhoneNumber?: string | null;
    /**
     * Серия паспорта
     */
    series?: string | null;
    /**
     * Номер паспорта
     */
    number?: string | null;
    /**
     * Дата выдачи
     */
    issueDate?: DateOnly | null;
    /**
     * Кем выдан
     */
    issuedBy?: string | null;
    /**
     * Адрес регистрации
     */
    registrationAddress?: string | null;
    /**
     * Место жительства
     */
    address?: string | null;
    /**
     * Почтовый адрес
     */
    postCode?: string | null;
    /**
     * Место рождения
     */
    dateOfBirth?: DateOnly | null;
    /**
     * Место рождения
     */
    placeBirth?: string | null;
}


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateTime } from '@lib-utils';
import { CallListStateType } from './callListStateType';


/**
 * Данные клиента
 */
export interface CollectionCallListDto { 
    id?: number;
    /**
     * Наименование
     */
    name?: string | null;
    callListStateType?: CallListStateType;
    /**
     * Всего активностей
     */
    countActivities?: number;
    /**
     * Всего активностей
     */
    countProcessedActivities?: number;
    /**
     * Ответственный
     */
    responsible?: string | null;
    /**
     * Дата создания
     */
    created?: DateTime;
}


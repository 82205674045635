import { GridOptions } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, tap } from 'rxjs';
import { Nullable } from '@lib-utils';
import { currencyCellDef, dateCellDef, getActionCellDef, GridComponent, GridGetDataCallback } from '@lib-widgets/grid';
import { CollectionContactDto, CollectionEnforcementDealDto } from '@lib-collection/api';
import { ProceedingType } from '@lib-collection/utils';
import { ContactTypeMap, StatusMap } from './models';
import { EnforcementProceedingService, JudicialProceedingService } from './services';
import { ProceedingService } from './services/abstract';

function isContact(contact: unknown): contact is CollectionContactDto {
  return !!contact;
}

const GET_GRID_OPTIONS = (context: ProceedingListComponent): GridOptions<CollectionEnforcementDealDto> => ({
  columnDefs: [
    { field: 'clientId', headerName: 'ID Клиента', initialWidth: 120 },
    { field: 'contractId', headerName: 'ID договора', initialWidth: 120 },
    { field: 'id', headerName: 'ID дела', initialWidth: 90 },
    { field: 'debtId', headerName: 'ID долга', initialWidth: 90 },
    {
      field: 'contact',
      headerName: 'ФИО',
      initialWidth: 150,
      valueFormatter: ({ value }) => (isContact(value) && value.contactPerson) || '',
    },
    {
      field: 'contact.contactType',
      headerName: 'Тип лица',
      refData: ContactTypeMap,
    },
    {
      field: 'bank',
      headerName: 'Банк',
      initialWidth: 150,
    },
    {
      field: 'responsible',
      headerName: 'Ответственный',
      initialWidth: 150,
    },
    {
      field: 'startDate',
      headerName: 'Дата возбуждения дела',
      initialWidth: 150,
      ...dateCellDef,
    },
    {
      field: 'courtDealId',
      headerName: '№ исп. Производства',
      initialWidth: 90,
    },
    {
      field: 'status',
      headerName: 'Статус',
      initialWidth: 150,
      refData: StatusMap,
    },
    {
      field: 'approvedDuty',
      headerName: 'Утвержденная пошлина',
      initialWidth: 150,
      ...currencyCellDef,
    },
    {
      field: 'endDate',
      headerName: 'Дата приостановления',
      initialWidth: 150,
      ...dateCellDef,
    },
    {
      field: 'court',
      headerName: 'Название суда',
      initialWidth: 150,
    },
    {
      field: 'number',
      headerName: 'Номер дела в суде',
      initialWidth: 150,
    },
    {
      field: 'comment',
      headerName: 'Комментарий',
      initialWidth: 150,
    },
    {
      colId: 'id',
      initialPinned: 'right',
      lockPinned: true,
      headerName: '',
      ...getActionCellDef<CollectionEnforcementDealDto>({
        icon: 'tuiIconEdit2',
        getLink: (data) => (data?.id ? context.getEditRoute(data.id) : undefined),
        hint: 'Редактировать',
      }),
    },
    {
      colId: 'id',
      initialPinned: 'right',
      lockPinned: true,
      headerName: '',
      ...getActionCellDef<CollectionEnforcementDealDto>({
        icon: 'tuiIconTrash',
        getAction$: (data) => context.remove$(data?.id!),
        hint: 'Удалить',
      }),
    },
  ],
  context,
});

@Component({
  selector: 'fnip-proceeding-list',
  templateUrl: './proceeding-list.component.html',
  styleUrls: ['./proceeding-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProceedingListComponent implements OnInit {
  @Input() personId?: number;

  @Input({ required: true })
  proceedingType?: ProceedingType;

  @Input()
  fromContract = false;

  @ViewChild(GridComponent) grid?: GridComponent;

  gridOptions = GET_GRID_OPTIONS(this);

  header = this.route.snapshot.data.pageHeader;

  proceedingListService!: ProceedingService;

  constructor(
    private judicialProceedingService: JudicialProceedingService,
    private enforcementProceedingService: EnforcementProceedingService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.proceedingType = this.proceedingType || this.route.snapshot.data.type;
    this.proceedingListService =
      this.proceedingType === ProceedingType.Judicial
        ? this.judicialProceedingService
        : this.enforcementProceedingService;
  }

  getBaseRoute = (fromContract: boolean, proceedingType: Nullable<ProceedingType>) =>
    fromContract
      ? [proceedingType === ProceedingType.Judicial ? 'judicial-proceedings' : 'enforcement-proceedings']
      : [];

  getCreateRoute = (fromContract: boolean, proceedingType: Nullable<ProceedingType>) => [
    ...this.getBaseRoute(fromContract, proceedingType),
    'new',
  ];

  getEditRoute = (id: number) => [
    ...this.getBaseRoute(this.fromContract, this.proceedingType ?? ProceedingType.Judicial),
    'edit',
    id,
  ];

  getProceedings$ =
    (proceedingListService: ProceedingService, personId: Nullable<number>): GridGetDataCallback =>
    (pagination) => {
      return proceedingListService.getProceedings(personId, pagination.page).pipe(map((res) => res));
    };

  remove$ = (id: number) => () =>
    this.proceedingListService.removeProceeding(id).pipe(tap(() => this.grid?.fetchData({ resetPage: true })));
}

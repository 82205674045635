/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContactEntity } from './contactEntity';
import { DateOnly } from '@lib-utils';
import { ContractEntity } from './contractEntity';


/**
 * Данные клиента
 */
export interface ClientEntity { 
    /**
     * Новая (несохранённая сущность)
     */
    readonly isNew?: boolean;
    /**
     * Идентификатор данных клиента
     */
    id?: number;
    /**
     * Идентификатор данных клиента из выгрузки csv по полю \"Клиент #Id\"
     */
    externalId?: number | null;
    /**
     * Имя
     */
    firstName?: string | null;
    /**
     * Отчество
     */
    patronymic?: string | null;
    /**
     * Фамилия
     */
    lastName?: string | null;
    /**
     * Дата рождения заемшика
     */
    dateOfBirth?: DateOnly | null;
    /**
     * Место рождения заемщика
     */
    birthPlace?: string | null;
    /**
     * Серия паспорта заемщика
     */
    series?: string | null;
    /**
     * Номер паспорта заемщика
     */
    number?: string | null;
    /**
     * Код подразделения выдавшего паспорт
     */
    departmentCode?: string | null;
    /**
     * Дата выдачи паспорта заемщика
     */
    issuedDate?: DateOnly | null;
    /**
     * Кем выдан Паспорт заемщику
     */
    issuedBy?: string | null;
    /**
     * Адрес регистрации заемщика
     */
    registrationAddress?: string | null;
    /**
     * Место жительства заемщика
     */
    factAddress?: string | null;
    /**
     * Почтовый адрес заемщика
     */
    postAddress?: string | null;
    /**
     * Телефон Мобильный
     */
    mobilePhone?: string | null;
    /**
     * Телефон рабочий
     */
    jobPhone?: string | null;
    /**
     * Телефон Домашний
     */
    homePhone?: string | null;
    /**
     * Адрес эл.почты
     */
    email?: string | null;
    /**
     * Кредитные договора
     */
    contracts?: Array<ContractEntity> | null;
    /**
     * Контакты
     */
    contacts?: Array<ContactEntity> | null;
}


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>Fnip.Collection.Utils.Enums.CallListStateType</b></p><p>Members:</p> <ul> <p><li><i>New</i> - Pre condition 5 Dial</li></p> <p><li><i>Export</i> - Экспортировано</li></p> </ul> 
 */
export type CallListStateType = 'New' | 'Export';

export const CallListStateType = {
    New: 'New' as CallListStateType,
    Export: 'Export' as CallListStateType
};


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OverdueReasonType } from './overdueReasonType';
import { ActivityContractClientRequest } from './activityContractClientRequest';
import { DateTime } from '@lib-utils';


/**
 * Кредитные договора из активности
 */
export interface ActivityContractRequest { 
    overdueReasonType?: OverdueReasonType;
    /**
     * Обещанная сумма оплаты
     */
    promiseSum?: number | null;
    /**
     * Обещанная дата оплаты
     */
    promiseDate?: DateTime | null;
    /**
     * КД
     */
    contractId?: number;
    /**
     * Информация по клиентах
     */
    clients?: Array<ActivityContractClientRequest> | null;
}


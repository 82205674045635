import { ContactType, CourtStatusType } from '@lib-collection/api';

export const StatusMap: Record<CourtStatusType, string> = {
  Discontinued: 'Прекращено',
  DocumentAccept: 'Документы получены',
  DocumentRequest: 'Запрос документов',
  HaveLawsuit: 'Есть иск',
  HaveOrder: 'Есть приказ',
  Unknown: 'Неопределен',
};

export const ContactTypeMap: Record<ContactType, string> = {
  Coborrower: 'Созаемщик',
  Guarantor: 'Поручитель',
};

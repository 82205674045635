/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CollectionReportFilter } from './collectionReportFilter';
import { CollectionReportBuilderTaskStatus } from './collectionReportBuilderTaskStatus';
import { UserInfoDto } from './userInfoDto';
import { CollectionReportType } from './collectionReportType';
import { DateTime } from '@lib-utils';


/**
 * Задача генерации отчета Collection
 */
export interface CollectionReportBuilderTaskDto { 
    id?: number;
    filter?: CollectionReportFilter;
    reportType?: CollectionReportType;
    /**
     * Процент выполнения.
     */
    progress?: number;
    creator?: UserInfoDto;
    /**
     * Дата когда пользовател инициировал создание отчета.
     */
    created?: DateTime;
    status?: CollectionReportBuilderTaskStatus;
}


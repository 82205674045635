/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CollectionPersonModel } from './collectionPersonModel';
import { DateOnly } from '@lib-utils';


/**
 * Документ
 */
export interface CollectionDocumentModel { 
    /**
     * Идентификатор
     */
    id?: number;
    /**
     * Идентификатор данных клиента
     */
    personId?: number | null;
    person?: CollectionPersonModel;
    /**
     * Тип документа
     */
    documentType?: string | null;
    /**
     * Серия
     */
    series?: string | null;
    /**
     * Номер
     */
    number?: string | null;
    /**
     * Код подразделения
     */
    departmentCode?: string | null;
    /**
     * Кем выдан Паспорт заемщику
     */
    issuedBy?: string | null;
    /**
     * Дата выдачи паспорта заемщика
     */
    issuedDate?: DateOnly | null;
    /**
     * Действует до
     */
    validUntill?: DateOnly | null;
    /**
     * ИНН
     */
    inn?: string | null;
    /**
     * СНИЛС
     */
    snils?: string | null;
    /**
     * Адрес регистрации заемщика
     */
    registrationAddress?: string | null;
    /**
     * Место жительства заемщика
     */
    factAddress?: string | null;
    /**
     * Место рождения заемщика
     */
    placeBirth?: string | null;
}


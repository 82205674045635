/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Sex } from './sex';


/**
 * Информация о пользователе
 */
export interface UserInfoDto { 
    id?: number;
    /**
     * Имя пользователя
     */
    firstName?: string | null;
    /**
     * Фамилия пользователя
     */
    lastName?: string | null;
    /**
     * Отчество пользователя
     */
    middleName?: string | null;
    /**
     * Электронная почта пользователя
     */
    email?: string | null;
    /**
     * Должность пользователя
     */
    position?: string | null;
    /**
     * Полное имя пользователя
     */
    readonly fullName?: string | null;
    sex?: Sex;
}


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CollectionReportBuilderTaskDto } from './collectionReportBuilderTaskDto';
import { PageResult } from './pageResult';


export interface CollectionReportBuilderTaskDtoPageResultData { 
    data?: Array<CollectionReportBuilderTaskDto> | null;
    page?: PageResult;
}


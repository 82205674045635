/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PhysicalPersonModel } from './physicalPersonModel';
import { DateTime } from '@lib-utils';


/**
 * Бизнес представление пользователя
 */
export interface UserModel { 
    /**
     * Идентификатор
     */
    id?: number;
    /**
     * Идентификатор пользователя, создавшего сущность
     */
    creatorId?: number;
    creator?: UserModel;
    /**
     * Дата создания сущности
     */
    created?: DateTime;
    /**
     * Идентификатор пользователя, обновившего сущность
     */
    updatorId?: number;
    updator?: UserModel;
    /**
     * Дата обновления сущность
     */
    updated?: DateTime;
    /**
     * Идентификатор пользователя
     */
    physicalPersonId?: number;
    physicalPerson?: PhysicalPersonModel;
    /**
     * Почта пользователя
     */
    email?: string | null;
    /**
     * Идентификатор роли пользователя
     */
    roleId?: number | null;
    /**
     * Должность пользователя
     */
    position?: string | null;
    /**
     * Хэш пароля пользователя
     */
    passwordHash?: string | null;
    /**
     * Признак активной учётной записи
     */
    isActive?: boolean;
}


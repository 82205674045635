/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityEntity } from './activityEntity';
import { ContractDto } from './contractDto';
import { ActivityContractClientDto } from './activityContractClientDto';


/**
 * Кредитные договора из активности
 */
export interface ActivityContractDto { 
    id?: number;
    /**
     * Активность
     */
    activityId?: number | null;
    activity?: ActivityEntity;
    /**
     * КД
     */
    contractId?: number | null;
    contract?: ContractDto;
    /**
     * Информация по клиентах
     */
    clients?: Array<ActivityContractClientDto> | null;
}


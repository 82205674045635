import { FormControl, FormGroup } from '@angular/forms';
import { ExtendedFormArray, ExtendedFormControlsOf, ExtendedFormGroup, ExtendedFormGroupOptions } from '@lib-form';
import { FormControlsOfNew, Nullable, omitNullable, toTuiDateTime } from '@lib-utils';
import {
  ActivityContractClientDto,
  ActivityContractDto,
  ActivityDto,
  ClientModel,
  ContractDto,
} from '@lib-collection/api';
import { FeActivityDto } from '@lib-collection/api-middleware';

export type ActivityForm = ReturnType<typeof createActivityForm>;

export function createActivityForm(
  activity: Nullable<ActivityDto>,
  contracts: Nullable<ContractDto[]>,
  options: ExtendedFormGroupOptions,
) {
  return new ExtendedFormGroup(
    {
      id: new FormControl(activity?.id),
      clientId: new FormControl(activity?.clientId),
      client: createActivityClientForm(activity?.client),
      activityType: new FormControl(activity?.activityType),
      comment: new FormControl(activity?.comment),
      totalResultType: new FormControl(activity?.totalResultType),
      contactDate: new FormControl(activity?.contactDate),
      nextContactDate: new FormControl(toTuiDateTime(activity?.nextContactDate)),
      overdueReasonType: new FormControl(activity?.overdueReasonType),
      promiseSum: new FormControl(activity?.promiseSum),
      promiseDate: new FormControl(activity?.promiseDate),
      contracts: new ExtendedFormArray(
        activity?.contracts?.map((contract) => createActivityContractForm(contract, contracts)) ?? [],
        { makeControlFn: (data) => createActivityContractForm(omitNullable(data), contracts) },
      ),
    } satisfies FormControlsOfNew<FeActivityDto>,
    options,
  );
}

export function createActivityClientForm(client: Nullable<ClientModel>) {
  return new FormGroup<ExtendedFormControlsOf<ClientModel>>({
    firstName: new FormControl(client?.firstName),
    patronymic: new FormControl(client?.patronymic),
    lastName: new FormControl(client?.lastName),
    mobilePhone: new FormControl(client?.mobilePhone),
  });
}

export type ActivityContractForm = ReturnType<typeof createActivityContractForm>;

export function createActivityContractForm(
  contract: Nullable<ActivityContractDto>,
  contracts: Nullable<ContractDto[]>,
) {
  return new FormGroup({
    id: new FormControl(contract?.id),
    contractId: new FormControl(contract?.contractId),
    contract: createContractForm(contracts?.find((c) => c.id === contract?.contractId)),
    clients: new ExtendedFormArray(contract?.clients?.map(createActivityContractClientForm) ?? [], {
      makeControlFn: (data) => createActivityContractClientForm(omitNullable(data)),
    }),
  } satisfies FormControlsOfNew<ActivityContractDto>);
}

export function createContractForm(contract: Nullable<ContractDto>) {
  return new FormGroup({
    id: new FormControl(contract?.id),
    contractNumber: new FormControl(contract?.contractNumber),
    creditStartDate: new FormControl(contract?.creditStartDate),
    projectType: new FormControl(contract?.projectType),
    // TODO: Уточнить, какой daysDelay использовать
    daysDelayFifo: new FormControl(contract?.daysDelayFifo),
    overdueDebt: new FormControl(contract?.overdueDebt),
  } satisfies FormControlsOfNew<ContractDto>);
}

export type ActivityContractClientForm = ReturnType<typeof createActivityContractClientForm>;

export function createActivityContractClientForm(client: Nullable<ActivityContractClientDto>) {
  return new FormGroup({
    id: new FormControl(client?.id),
    clientId: new FormControl(client?.clientId),
    phone: new FormControl(client?.phone),
    callResultType: new FormControl(client?.callResultType),
  } satisfies FormControlsOfNew<ActivityContractClientDto>);
}

/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientEntity } from './clientEntity';
import { ContractEntity } from './contractEntity';


/**
 * Модель созаёмщика
 */
export interface CoborrowerEntity { 
    /**
     * Новая (несохранённая сущность)
     */
    readonly isNew?: boolean;
    /**
     * Идентификатор заявки.
     */
    contractId?: number;
    contract?: ContractEntity;
    /**
     * Идентификатор Клиента
     */
    clientId?: number;
    client?: ClientEntity;
    /**
     * Идентификатор созаёмщика.
     */
    id?: number;
}


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientEntity } from './clientEntity';
import { DateTime } from '@lib-utils';
import { UserEntity } from './userEntity';
import { CallResultType } from './callResultType';


/**
 * Клиенты КД из активности
 */
export interface ActivityContractClientEntity { 
    /**
     * Новая (несохранённая сущность)
     */
    readonly isNew?: boolean;
    /**
     * Идентификатор пользователя, создавшего сущность
     */
    creatorId?: number;
    creator?: UserEntity;
    /**
     * Дата создания сущности
     */
    created?: DateTime;
    /**
     * Идентификатор пользователя, обновившего сущность
     */
    updatorId?: number;
    updator?: UserEntity;
    /**
     * Дата обновления сущность
     */
    updated?: DateTime;
    /**
     * Идентификатор
     */
    id?: number;
    /**
     * Номер телефона
     */
    phone?: string | null;
    callResultType?: CallResultType;
    /**
     * Следующая дата контакта
     */
    nextContactDate?: DateTime | null;
    /**
     * Клиент
     */
    clientId?: number | null;
    client?: ClientEntity;
}


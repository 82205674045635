/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>Fnip.Collection.Utils.Enums.ContactType</b></p><p>Members:</p> <ul> <p><li><i>Guarantor</i> - Поручитель</li></p> <p><li><i>Coborrower</i> - Созаемщик</li></p> </ul> 
 */
export type ContactType = 'Guarantor' | 'Coborrower';

export const ContactType = {
    Guarantor: 'Guarantor' as ContactType,
    Coborrower: 'Coborrower' as ContactType
};


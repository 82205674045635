import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TuiDay } from '@taiga-ui/cdk';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { beToFeOptions, getRouteParams, SelectOptions, SharedValidators, toTuiDate } from '@lib-utils';
import {
  CollectionCourtDealDto,
  CollectionEnforcementDealDto,
  CourtStatusType,
  CrmCollectionApiService,
} from '@lib-collection/api';
import { ContractViewTabs, getBasePath, ProceedingType } from '@lib-collection/utils';
import { StatusMap } from '../models';
import { EnforcementProceedingService, JudicialProceedingService } from '../services';
import { ProceedingService } from '../services/abstract';

@Component({
  selector: 'fnip-edit-proceedings',
  templateUrl: './edit-proceedings.component.html',
  styleUrls: ['./edit-proceedings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditProceedingsComponent {
  readonly basePath = getBasePath();

  routerParams = getRouteParams();

  proceedingType: ProceedingType = this.route.snapshot.data.type;

  header = this.route.snapshot.data.pageHeader;

  proceedingService: ProceedingService = inject(
    this.proceedingType === ProceedingType.Judicial ? JudicialProceedingService : EnforcementProceedingService,
  );

  form = new FormGroup({
    clientId: new FormControl<number | null>(null, [Validators.required, SharedValidators.numberOnly]),
    contractId: new FormControl<number | null>(null, [SharedValidators.numberOnly]),
    debtId: new FormControl<number | null>(null, [Validators.required, SharedValidators.numberOnly]),
    courtDealId: new FormControl<number | null>(null, [SharedValidators.numberOnly]),
    startDate: new FormControl<TuiDay | null>(null),
    endDate: new FormControl<TuiDay | null>(null),
    courtSubmitDate: new FormControl<TuiDay | null>(null),
    contractTerminationDate: new FormControl<TuiDay | null>(null),
    decisionDate: new FormControl<TuiDay | null>(null),
    effectiveDate: new FormControl<TuiDay | null>(null),
    paymentDutyDate: new FormControl<TuiDay | null>(null),
    requestDate: new FormControl<TuiDay | null>(null),
    overdueDate: new FormControl<TuiDay | null>(null),
    enforcementDate: new FormControl<TuiDay | null>(null),
    settlementDate: new FormControl<TuiDay | null>(null),
    decisionClaimDate: new FormControl<TuiDay | null>(null),
    bank: new FormControl<string>(''),
    court: new FormControl<string>(''),
    comment: new FormControl<string>(''),
    status: new FormControl<CourtStatusType | null>(null),
    feedMethod: new FormControl<string | null>(null),
    number: new FormControl<string | null>(null),
    sum: new FormControl<number | null>(null, [SharedValidators.numberOnly]),
    approvedDuty: new FormControl<number | null>(null, [SharedValidators.numberOnly]),
    result: new FormControl<string | null>(null),
    incomingNumber: new FormControl<string | null>(null),
    sumSettlement: new FormControl<number | null>(null, [SharedValidators.numberOnly]),
    numberPayment: new FormControl<string | null>(null),
    responsible: new FormControl<string | null>(null),
    personId: new FormControl<number | null>(this.routerParams.personId ? +this.routerParams.personId : null),
    contactId: new FormControl<number | null>(this.routerParams.contactId ? +this.routerParams.contactId : null),
  });

  backRoute = this.routerParams.id
    ? [this.basePath, 'contracts', 'edit', this.routerParams.id]
    : [this.basePath, `${this.proceedingType}-proceedings`];

  proceeding$ = this.routerParams.proceedingId
    ? this.proceedingService.getProceeding(+this.routerParams.proceedingId)
    : of(null);

  statuses: SelectOptions = Object.entries(StatusMap).map((entry) => ({
    value: entry[0],
    label: entry[1],
  }));

  courtDeals$ = this.crmCollectionApiService
    .apiCrmCollectionCourtDealsGet(+this.routerParams.id, 1, 999)
    .pipe(map((result) => beToFeOptions(result.data?.data ?? [], { labelPath: 'id' })));

  constructor(
    private crmCollectionApiService: CrmCollectionApiService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  patchFormWith(result: CollectionEnforcementDealDto | CollectionCourtDealDto) {
    const {
      startDate,
      endDate,
      courtSubmitDate,
      contractTerminationDate,
      decisionDate,
      effectiveDate,
      paymentDutyDate,
      requestDate,
      overdueDate,
      enforcementDate,
      settlementDate,
      decisionClaimDate,
    } = result;

    this.form.patchValue({
      ...result,
      startDate: startDate ? toTuiDate(startDate) : null,
      endDate: endDate ? toTuiDate(endDate) : null,
      courtSubmitDate: courtSubmitDate ? toTuiDate(courtSubmitDate) : null,
      contractTerminationDate: contractTerminationDate ? toTuiDate(contractTerminationDate) : null,
      decisionDate: decisionDate ? toTuiDate(decisionDate) : null,
      effectiveDate: effectiveDate ? toTuiDate(effectiveDate) : null,
      paymentDutyDate: paymentDutyDate ? toTuiDate(paymentDutyDate) : null,
      requestDate: requestDate ? toTuiDate(requestDate) : null,
      overdueDate: overdueDate ? toTuiDate(overdueDate) : null,
      enforcementDate: enforcementDate ? toTuiDate(enforcementDate) : null,
      settlementDate: settlementDate ? toTuiDate(settlementDate) : null,
      decisionClaimDate: decisionClaimDate ? toTuiDate(decisionClaimDate) : null,
    });
  }

  getSaveCallback$ = () => {
    const {
      startDate,
      endDate,
      status,
      courtSubmitDate,
      contractTerminationDate,
      decisionDate,
      effectiveDate,
      paymentDutyDate,
      requestDate,
      overdueDate,
      enforcementDate,
      settlementDate,
      decisionClaimDate,
    } = this.form.value;
    const data: CollectionEnforcementDealDto = {
      id: +this.routerParams.proceedingId,
      ...this.form.value,
      startDate: startDate?.toLocalNativeDate().toISOString(),
      endDate: endDate?.toLocalNativeDate().toISOString(),
      courtSubmitDate: courtSubmitDate?.toLocalNativeDate().toISOString(),
      contractTerminationDate: contractTerminationDate?.toLocalNativeDate().toISOString(),
      decisionDate: decisionDate?.toLocalNativeDate().toISOString(),
      effectiveDate: effectiveDate?.toLocalNativeDate().toISOString(),
      paymentDutyDate: paymentDutyDate?.toLocalNativeDate().toISOString(),
      requestDate: requestDate?.toLocalNativeDate().toISOString(),
      overdueDate: overdueDate?.toLocalNativeDate().toISOString(),
      enforcementDate: enforcementDate?.toLocalNativeDate().toISOString(),
      settlementDate: settlementDate?.toLocalNativeDate().toISOString(),
      decisionClaimDate: decisionClaimDate?.toLocalNativeDate().toISOString(),
      status: status ?? undefined, // api model require value or undefined (not nullable)
    };

    const action$: Observable<unknown> = this.routerParams.proceedingId
      ? this.proceedingService.updateProceeding(+this.routerParams.proceedingId, data)
      : this.proceedingService.addProceeding(data);
    return action$.pipe(tap(() => this.navigateToListPage()));
  };

  getCancelCallback$ = () => this.navigateToListPage();

  private navigateToListPage(): void {
    if (this.routerParams.id) {
      this.router.navigate(this.backRoute, {
        fragment: `${
          this.proceedingType === ProceedingType.Judicial ? ContractViewTabs.CourtDeal : ContractViewTabs.Proceedings
        }`,
      });
    } else {
      this.router.navigate(this.backRoute);
    }
  }
}

/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * выезды и комментарии по взаимодействию с должником
 */
export interface OutsourcingDeparturesModel { 
    /**
     * Идентификатор
     */
    id?: number;
    /**
     * Номер контракта
     */
    creditContractNumber?: string | null;
    /**
     * Идентификатор банка
     */
    bankId?: number;
    /**
     * Тип адреса
     */
    addrType?: string | null;
    /**
     * Результат выезда
     */
    resultId?: number;
    /**
     * Автомобиль на месте
     */
    isCarInPlace?: boolean;
    /**
     * Комментарий
     */
    comment?: string | null;
    /**
     * Дата выезда
     */
    depatureDate?: string | null;
}


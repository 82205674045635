/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>Fnip.Collection.Models.Outsourcing.ExcelModelFieldState</b></p><p>Members:</p> <ul> <p><li><i>Ok</i> - Поле соответствует модели</li></p> <p><li><i>New</i> - новое</li></p> <p><li><i>NotExist</i> - отсутствует</li></p> <p><li><i>WrongType</i> - не соответствует тип</li></p> </ul> 
 */
export type ExcelModelFieldState = 'Ok' | 'New' | 'NotExist' | 'WrongType';

export const ExcelModelFieldState = {
    Ok: 'Ok' as ExcelModelFieldState,
    New: 'New' as ExcelModelFieldState,
    NotExist: 'NotExist' as ExcelModelFieldState,
    WrongType: 'WrongType' as ExcelModelFieldState
};


export enum ContractViewTabs {
  Credit = 'Credit',
  Payments = 'Payments',
  Personal = 'Personal',
  Participants = 'Participants',
  Contracts = 'Contracts',
  Pledge = 'Pledge',
  Activities = 'Activities',
  EmploymentIncome = 'EmploymentIncome',
  Documents = 'Documents',
  Tags = 'Tags',
  Outsource = 'Outsource',
  Property = 'Property',
  Phones = 'Phones',
  // TODO: Удалить с соответствующими компонентами после финализации шаблона
  Archive = 'Archive',
  Zalogs = 'Zalogs',
  Contacts = 'Contacts',
  Proceedings = 'Proceedings',
  CourtDeal = 'CourtDeal',
  GuarantContracts = 'GuarantContracts',
  Accounts = 'Accounts',
  Bankruptcy = 'Bankruptcy',
  Dept = 'Debt',
}

// TODO: Добавлен в рамках FNCOL-108
export const CONTRACT_TABS_VISIBLE = [
  ContractViewTabs.Credit,
  ContractViewTabs.Payments,
  ContractViewTabs.Personal,
  ContractViewTabs.Participants,
  ContractViewTabs.Contracts,
  ContractViewTabs.Pledge,
  ContractViewTabs.Activities,
  ContractViewTabs.EmploymentIncome,
  ContractViewTabs.Documents,
  ContractViewTabs.Tags,
  ContractViewTabs.Outsource,
  ContractViewTabs.Property,
  ContractViewTabs.Phones,
];

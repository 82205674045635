/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PhysicalPersonEntity } from './physicalPersonEntity';
import { DateTime } from '@lib-utils';


/**
 * Модель пользователя
 */
export interface UserEntity { 
    /**
     * Новая (несохранённая сущность)
     */
    readonly isNew?: boolean;
    /**
     * Идентификатор пользователя
     */
    id?: number;
    /**
     * Идентификатор Физ лица
     */
    physicalPersonId?: number | null;
    physicalPerson?: PhysicalPersonEntity;
    /**
     * Электронная почта пользователя
     */
    email?: string | null;
    /**
     * Должность пользователя
     */
    position?: string | null;
    /**
     * Идентификатор роли пользователя
     */
    roleId?: number | null;
    /**
     * Хэш пароля пользователя
     */
    passwordHash?: string | null;
    /**
     * Признак активной учётной записи
     */
    isActive?: boolean;
    /**
     * Идентификатор пользователя, создавшего сущность
     */
    creatorId?: number | null;
    creator?: UserEntity;
    /**
     * Дата создания сущности
     */
    created?: DateTime;
    /**
     * Идентификатор пользователя, обновившего сущность
     */
    updatorId?: number | null;
    updator?: UserEntity;
    /**
     * Дата обновления сущность
     */
    updated?: DateTime;
}


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>Fnip.Collection.Utils.Enums.SegmentPriorityType</b></p><p>Members:</p> <ul> <p><li><i>Low</i> - Низкий</li></p> <p><li><i>Middle</i> - Средний</li></p> <p><li><i>High</i> - Высокий</li></p> </ul> 
 */
export type SegmentPriorityType = 'Low' | 'Middle' | 'High';

export const SegmentPriorityType = {
    Low: 'Low' as SegmentPriorityType,
    Middle: 'Middle' as SegmentPriorityType,
    High: 'High' as SegmentPriorityType
};


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CallListStateType } from './callListStateType';


/**
 * Запрос получения списка
 */
export interface CollectionCallListRequest { 
    /**
     * Наименование
     */
    name?: string | null;
    callListStateType?: CallListStateType;
    /**
     * Ответственный
     */
    responsible?: string | null;
}


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>Fnip.Collection.Utils.Enums.CollectionReportSortFields</b></p><p>Members:</p> <ul> <p><li><i>Id</i> - Идентификатор</li></p> <p><li><i>DateCreated</i> - Дата создания.</li></p> <p><li><i>DateUpdated</i> - Дата обновления.</li></p> <p><li><i>ReportType</i> - Тип отчета</li></p> <p><li><i>Status</i> - Статус</li></p> </ul> 
 */
export type CollectionReportSortFields = 'Id' | 'DateCreated' | 'DateUpdated' | 'ReportType' | 'Status';

export const CollectionReportSortFields = {
    Id: 'Id' as CollectionReportSortFields,
    DateCreated: 'DateCreated' as CollectionReportSortFields,
    DateUpdated: 'DateUpdated' as CollectionReportSortFields,
    ReportType: 'ReportType' as CollectionReportSortFields,
    Status: 'Status' as CollectionReportSortFields
};


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CollectionContractDto } from './collectionContractDto';


/**
 * Залоги по кредитному договору
 */
export interface CollectionContractZalogDto { 
    /**
     * Идентификатор
     */
    id?: number;
    /**
     * Идентификатор кредитного договора
     */
    contractId?: number | null;
    contract?: CollectionContractDto;
    /**
     * Залог авто:Модель и марка автомобиля
     */
    model?: string | null;
    /**
     * Залог авто: VIN
     */
    vin?: string | null;
    /**
     * Залог авто: год производства автомобиля
     */
    issueYear?: string | null;
    /**
     * Залог авто: цвет автомобиля
     */
    vehicleColor?: string | null;
    /**
     * Залог авто: номер ПТС
     */
    vehiclePassport?: string | null;
    /**
     * Залог недвижимостти: адрес местонахождения
     */
    realEstateAddress?: string | null;
    /**
     * Залог недвижимостти: кадастровый/условный номер
     */
    realEstateNumber?: string | null;
}


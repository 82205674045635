/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CollectionContractDto } from './collectionContractDto';
import { DateOnly } from '@lib-utils';


/**
 * Платежи по кредитному договору
 */
export interface CollectionContractPaymentDto { 
    id?: number;
    /**
     * ID со стороны банка из выгрузки
     */
    externalId?: number;
    /**
     * Идентификатор кредитного договора
     */
    contractId?: number | null;
    contract?: CollectionContractDto;
    /**
     * Номер заявки
     */
    applicationNumber?: string | null;
    /**
     * Дата платежа
     */
    paymentDate?: DateOnly | null;
    /**
     * Документ
     */
    document?: string | null;
    /**
     * Номер документа
     */
    documentNumber?: string | null;
    /**
     * Сумма
     */
    sum?: number | null;
    /**
     * Сумма эквивалента
     */
    sumEquiv?: number | null;
    /**
     * Валюта
     */
    currency?: string | null;
    /**
     * Счет Дебет
     */
    accountDebit?: string | null;
    /**
     * Счет Кредит
     */
    accountCredit?: string | null;
    /**
     * Состояние
     */
    state?: string | null;
    /**
     * Дата проводки
     */
    postingDate?: DateOnly | null;
    /**
     * Назначение платежа
     */
    purpose?: string | null;
    /**
     * БИК банка
     */
    bik?: string | null;
    /**
     * Очередность
     */
    order?: string | null;
    /**
     * Дата создания
     */
    createdDate?: DateOnly | null;
    /**
     * ИНН плательщика
     */
    payerInn?: string | null;
    /**
     * Счет плательщика
     */
    payerAccount?: string | null;
    /**
     * ИНН Получателя
     */
    recepientInn?: string | null;
    /**
     * Счет получателя
     */
    recepientAccount?: string | null;
    /**
     * Плательщик
     */
    payer?: string | null;
    /**
     * Получатель
     */
    recepient?: string | null;
    /**
     * Уникальный номер документа
     */
    uniqueDocNumber?: string | null;
    /**
     * Астрономическая дата проводки
     */
    astronomPostingDate?: DateOnly | null;
    /**
     * Дата валютирования
     */
    valueDate?: DateOnly | null;
    /**
     * Подразделение
     */
    subdivision?: string | null;
    /**
     * Филиал
     */
    filial?: string | null;
    /**
     * Код операции
     */
    operationCode?: string | null;
    /**
     * Тип операции
     */
    operationType?: string | null;
}


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityEntity } from './activityEntity';
import { ActivityContractClientEntity } from './activityContractClientEntity';
import { ContractEntity } from './contractEntity';
import { DateTime } from '@lib-utils';
import { UserEntity } from './userEntity';


/**
 * Кредитные договора из активности
 */
export interface ActivityContractEntity { 
    /**
     * Новая (несохранённая сущность)
     */
    readonly isNew?: boolean;
    /**
     * Идентификатор пользователя, создавшего сущность
     */
    creatorId?: number;
    creator?: UserEntity;
    /**
     * Дата создания сущности
     */
    created?: DateTime;
    /**
     * Идентификатор пользователя, обновившего сущность
     */
    updatorId?: number;
    updator?: UserEntity;
    /**
     * Дата обновления сущность
     */
    updated?: DateTime;
    /**
     * Идентификатор
     */
    id?: number;
    /**
     * Активность
     */
    activityId?: number;
    activity?: ActivityEntity;
    /**
     * КД
     */
    contractId?: number;
    contract?: ContractEntity;
    /**
     * Информация по клиентах
     */
    clients?: Array<ActivityContractClientEntity> | null;
}


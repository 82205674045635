/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Обновление сумм задолженности
 */
export interface OutsourcingUpdateModel { 
    /**
     * Номер договора
     */
    creditContractNumber?: string | null;
    /**
     * Банк
     */
    bank?: string | null;
    /**
     * ФИО клиента
     */
    clientName?: string | null;
    /**
     * Дата образования задолженности по основному долгу
     */
    debtDate?: string | null;
    /**
     * Ссудная задолженность, руб.
     */
    loanDebt?: string | null;
    /**
     * Просроченная ссудная задолженность, руб.
     */
    overdueLoanDebt?: string | null;
    /**
     * Просроченная задолженность по процентам, руб.
     */
    overdueInterestDebt?: string | null;
    /**
     * Задолженность по пене за кредит, руб.
     */
    loanPenaltyDebt?: string | null;
    /**
     * Задолженность по пене за проценты, руб.
     */
    interestPenaltyDebt?: string | null;
    /**
     * Просроченная задолженность, руб.
     */
    overdueDebt?: string | null;
    /**
     * Общая задолженность, руб.
     */
    totalDebt?: string | null;
    /**
     * Срок непрерывной просрочки в днях
     */
    overdueDays?: string | null;
    /**
     * Выставлено требование на досрочный возврат
     */
    isDemand?: string | null;
    /**
     * Дата последнего платежа
     */
    lastPaymentDate?: string | null;
    /**
     * Сумма последнего платежа
     */
    lastPaymentAmount?: string | null;
    /**
     * Номер счета для списания
     */
    accountNumber?: string | null;
    /**
     * Место работы
     */
    workPlace?: string | null;
    /**
     * Адрес работы
     */
    workAddress?: string | null;
    /**
     * Обеспечение залога
     */
    collateral?: string | null;
    /**
     * Дата последнего ДТП
     */
    lastAccidentDate?: string | null;
    /**
     * Дата последней регистрации
     */
    lastRegistrationDate?: string | null;
    /**
     * Последнее рег. действие
     */
    lastRegistrationAction?: string | null;
    /**
     * Колличество ДТП
     */
    accidentCount?: string | null;
    /**
     * Количество регистраций
     */
    registrationCount?: string | null;
    /**
     * ФИО НС / наименование НС
     */
    insuredName?: string | null;
    /**
     * Адрес регистрации НС
     */
    insuredAddress?: string | null;
    /**
     * Дата рождения НС
     */
    insuredBirthDate?: string | null;
    /**
     * ИНН НС
     */
    insuredInn?: string | null;
    /**
     * Серия, номер документа НС
     */
    insuredDocument?: string | null;
    /**
     * Наименование операции регистрации
     */
    registrationOperationName?: string | null;
    /**
     * Дата операции регистрации
     */
    registrationOperationDate?: string | null;
    /**
     * Дата выдачи кредита
     */
    creditIssueDate?: string | null;
    /**
     * Госпошлина, руб.
     */
    stateDuty?: string | null;
    /**
     * ГРЗ (РСА)
     */
    carNumber?: string | null;
    /**
     * Страхователь ТС (РСА)
     */
    insurer?: string | null;
    /**
     * Собственник ТС (РСА)
     */
    owner?: string | null;
    /**
     * ТС используется в регионе (РСА)
     */
    carRegion?: string | null;
    /**
     * Отказ от взаимодействия
     */
    isRefuse?: string | null;
    /**
     * ФИО представителя
     */
    representativeName?: string | null;
    /**
     * Телефон представителя
     */
    representativePhone?: string | null;
    /**
     * Дата выгрузки
     */
    uploadDate?: string | null;
    /**
     * Документы
     */
    documents?: string | null;
}


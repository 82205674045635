/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TagType } from './tagType';
import { ContractDto } from './contractDto';


/**
 * Тег в КД
 */
export interface ContractTagDto { 
    id?: number;
    /**
     * Идентификатор
     */
    contractId?: number;
    contract?: ContractDto;
    tagType?: TagType;
}


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { CollectionContractFileType } from './collectionContractFileType';
import { DateTime } from '@lib-utils';


/**
 * Информация о файле
 */
export interface ContractFileInfoDto { 
    id?: number;
    /**
     * Имя файла
     */
    name?: string | null;
    type?: CollectionContractFileType;
    /**
     * Размер файла
     */
    size?: number;
    /**
     * Тип медиаконтента
     */
    contentType?: string | null;
    /**
     * Комментарий
     */
    comment?: string | null;
    creator?: UserModel;
    /**
     * Дата создания сущности
     */
    created?: DateTime;
}


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientEntity } from './clientEntity';


/**
 * Данные контракта
 */
export interface ContactEntity { 
    /**
     * Идентификатор сущности
     */
    id?: number;
    /**
     * Новая (несохранённая сущность)
     */
    readonly isNew?: boolean;
    /**
     * Комментарий
     */
    comment?: string | null;
    /**
     * Номер телефона
     */
    phoneNumber?: string | null;
    /**
     * Идентификатор клиента
     */
    clientId?: number;
    client?: ClientEntity;
}


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Отзыв контракта из работы
 */
export interface OutsourcingCancelModel { 
    /**
     * Номер договора
     */
    creditContractNumber?: string | null;
    /**
     * Банк
     */
    bank?: string | null;
    /**
     * ФИО клиента
     */
    clientName?: string | null;
    /**
     * Дата выдачи кредита
     */
    creditDate?: string | null;
    /**
     * Причина исключения из работы
     */
    cancelReason?: string | null;
    /**
     * Дата выгрузки
     */
    uploadDate?: string | null;
}


/**
 * FNIP
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>Fnip.Collection.Utils.Enums.TagType</b></p><p>Members:</p> <ul> <p><li><i>SellOther</i> - Email</li></p> <p><li><i>Act</i> - АКТ О НЕВОЗМОЖНОСТИ ВЗЫСКАНИЯ (АНВ)</li></p> <p><li><i>Bankruptcy</i> - Банкротство</li></p> <p><li><i>AdvocatNotAccepted</i> - Взаимодействие через адвоката (не принято: наруш. треб. ст. 8 ФЗ-230)</li></p> <p><li><i>AdvocatAccepted</i> - Взаимодействие через адвоката (принято)</li></p> <p><li><i>Accedent</i> - ДТП</li></p> <p><li><i>Imha</i> - ИМХА</li></p> <p><li><i>OptingOutNotAccepted</i> - Отказ от взаимодействия (не принято: наруш. треб. ст. 8 ФЗ-230)</li></p> <p><li><i>OptingOutAccepted</i> - Отказ от взаимодействия (принято)</li></p> <p><li><i>PlanImha</i> - Планируется ИМХА</li></p> <p><li><i>ClaimClient</i> - Претензионный клиент</li></p> <p><li><i>Death</i> - Смерть</li></p> <p><li><i>Total</i> - Тотал</li></p> <p><li><i>Mobilization</i> - ФЗ-377 (мобилизация)</li></p> <p><li><i>Demobilization</i> - ФЗ-377 (списание)</li></p> <p><li><i>Zalog</i> - Залог реализован</li></p> <p><li><i>Drz</i> - ДРЗ</li></p> <p><li><i>Compensation</i> - Отступное</li></p> <p><li><i>Balance</i> - Предложение на баланс</li></p> <p><li><i>Award</i> - Присуждение в натуре</li></p> <p><li><i>Arest</i> - Арест</li></p> <p><li><i>VoluntaryTransfer</i> - Добровольная передача</li></p> <p><li><i>ZalogTorg</i> - Залог реализован с торгов</li></p> </ul> 
 */
export type TagType = 'SellOther' | 'Act' | 'Bankruptcy' | 'AdvocatNotAccepted' | 'AdvocatAccepted' | 'Accedent' | 'Imha' | 'OptingOutNotAccepted' | 'OptingOutAccepted' | 'PlanImha' | 'ClaimClient' | 'Death' | 'Total' | 'Mobilization' | 'Demobilization' | 'Zalog' | 'Drz' | 'Compensation' | 'Balance' | 'Award' | 'Arest' | 'VoluntaryTransfer' | 'ZalogTorg';

export const TagType = {
    SellOther: 'SellOther' as TagType,
    Act: 'Act' as TagType,
    Bankruptcy: 'Bankruptcy' as TagType,
    AdvocatNotAccepted: 'AdvocatNotAccepted' as TagType,
    AdvocatAccepted: 'AdvocatAccepted' as TagType,
    Accedent: 'Accedent' as TagType,
    Imha: 'Imha' as TagType,
    OptingOutNotAccepted: 'OptingOutNotAccepted' as TagType,
    OptingOutAccepted: 'OptingOutAccepted' as TagType,
    PlanImha: 'PlanImha' as TagType,
    ClaimClient: 'ClaimClient' as TagType,
    Death: 'Death' as TagType,
    Total: 'Total' as TagType,
    Mobilization: 'Mobilization' as TagType,
    Demobilization: 'Demobilization' as TagType,
    Zalog: 'Zalog' as TagType,
    Drz: 'Drz' as TagType,
    Compensation: 'Compensation' as TagType,
    Balance: 'Balance' as TagType,
    Award: 'Award' as TagType,
    Arest: 'Arest' as TagType,
    VoluntaryTransfer: 'VoluntaryTransfer' as TagType,
    ZalogTorg: 'ZalogTorg' as TagType
};

